import React from 'react';
import avatar_image from '../assets/avatar.png';

const defaultSmallStyle = 'w-12 h-12 text-2xl p-1';
const defaultLargeStyle = 'w-32 h-32 text-6xl p-8 shadow-md';

const Avatar = ({image, size}) => {
	const defaultStyle = size === 'large' ? defaultLargeStyle : defaultSmallStyle;
	image = image && size === 'large' ? image.replace('_normal', '') : image; // use larger twitter avatar source

	return (
		<div className={defaultStyle + " default-avatar relative flex-shrink-0 rounded-full overflow-hidden"}>
			<img src={avatar_image} alt="avatar" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full" />
			{ image &&
				<img src={image} alt="" className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full rounded-full" />
			}
		</div>
	);
}

export default Avatar;