import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import agentAPI from '../../api/agentAPI';
import unsplashAPI from '../../api/unsplashAPI';
import Avatar from '../Avatar';
import Links from './Links';
import QueryButton from './QueryButton';
import ReportAgent from './ReportAgent';
import SaveAgentButton from '../SaveAgentButton';
import SimilarAgents from './SimilarAgents';
import TabContent from './TabContent';
import TwitterFeed from './TwitterFeed';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const AgentProfile = () => {
	const [agent, setAgent] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [heroImage, setHeroImage] = useState('');
	const [heroImageUserMeta, setHeroImageUserMeta] = useState('');

	const { name } = useParams();
	useDocumentTitle(name);

	const imageStyle = {
		backgroundImage: `linear-gradient(0deg, rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${heroImage})`
	}

	useEffect(() => {
		const getPhoto = async () => {
			try {
				const data = await unsplashAPI.fetchCatImage();
				setHeroImage(data.urls.raw + "&w=1920");
				setHeroImageUserMeta(data.user);
			} catch (error) {
				console.log(error);
			}
		}

		const fetchAgent = async () => {
			try {
				const agent = await agentAPI.fetchByName(name);
				setAgent(agent);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		}

		// COULDDO: Don't need to fetch agent if agent is passed directly ie. from SearchPage
		// if (location.state) {	// Agent has been passed through <Link> (ie. from SearchPage)
		// 	setAgent(location.state.agent);
		// 	setIsLoading(false);
		// } else {				// Page reached directly, need to fetch agent
		// 	fetchAgent();
		// }

		fetchAgent();
		getPhoto();

	}, [name])

	return (
		<div className="w-full">
			<div style={imageStyle} className="
				hero-image relative p-40 lg:p-56 w-full bg-cover bg-center bg-secondary-maroon
			">
				{ heroImageUserMeta &&
					<div className="absolute bottom-0 right-0 px-1 rounded-sm text-gray-200">
						Photo by <a href={`${heroImageUserMeta.links.html}?utm_source=query_cat&utm_medium=referral`} target="_blank" rel="noreferrer">{heroImageUserMeta.name}</a> on <a href="https://unsplash.com/?utm_source=query_cat&utm_medium=referral" target="_blank" rel="noreferrer">Unsplash</a>
					</div>
				}  
			</div>
			<div className="container py-12 md:py-16 px-8 xl:px-32 2xl:px-48">
				{ isLoading ? (
					<ContentLoader 
						speed={2}
						width={700}
						viewBox="0 0 572 340"
						backgroundColor="#F5F7FA"
						foregroundColor="#E4E7EB"
						className="w-full"
					>
						<circle cx="38" cy="38" r="38" /> 
						<rect x="0" y="90" rx="4" ry="4" width="238" height="14" />
						<rect x="0" y="110" rx="3" ry="3" width="148" height="10" />
						<rect x="0" y="136" rx="4" ry="4" width="572" height="14" />
						<rect x="0" y="156" rx="4" ry="4" width="572" height="14" />
						<rect x="0" y="176" rx="4" ry="4" width="572" height="14" />
						<rect x="0" y="196" rx="4" ry="4" width="572" height="14" />
						<rect x="0" y="243" rx="4" ry="4" width="184" height="94" /> 
						<rect x="194" y="243" rx="4" ry="4" width="184" height="94" /> 
						<rect x="385" y="243" rx="4" ry="4" width="184" height="94" />
					</ContentLoader>
				) : (
					<div className="wrapper">
						<div className="flex flex-col justify-between mb-12 lg:flex-row" key={agent._id}>
							<div className="agent-info flex-grow space-y-4 -mt-28 mb-8 lg:pr-12">
								<div className="avatar flex justify-center lg:justify-start">
									<Avatar image={agent.twitter_image} size='large'/>
								</div>
								<div className="flex flex-col items-center justify-between lg:flex-row">
									<div className='agent-meta space-y-4 2xl:space-y-2'>
										<div className="flex flex-col items-center lg:items-start 2xl:flex-row 2xl:items-center">
											<h4 className="text-center mr-4 mb-4 lg:mb-2 2xl:mb-0 lg:text-left">{agent.name}</h4>
											{ agent.is_accepting_submissions ? (
												<div className="pill success">● Open to Submissions</div>
											) : (
												<div className="pill error">● Closed to Submissions</div>
											)}
										</div>

										<div className="body-1 flex flex-wrap space-x-2 justify-center lg:justify-start">
											{ agent.agency &&
												<span>{agent.agency}</span>
											}
											{ agent.country &&
												<span>
													<span className="text-gray-200 mr-1">●</span>
													{agent.country}
												</span>
											}
										</div>
									</div>

									<div className="flex space-x-6 items-center mt-4 lg:mt-0">
										<SaveAgentButton agent={agent} size='large' />
										{ agent.query_button_link &&
											<QueryButton link={agent.query_button_link} />
										}
									</div>
								</div>
								<TabContent agent={agent} />
							</div>

							<div className="sidebar space-y-8 flex-shrink-0 lg:w-96">
								<Links agent={agent} />
								{ agent.twitter &&
									<TwitterFeed twitterUrl={agent.twitter} />
								}
								<ReportAgent agent={agent} />
							</div>
						</div>
						<SimilarAgents agent={agent} />
					</div>
				)}
			</div>
		</div>
	);
}

export default AgentProfile;