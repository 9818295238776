import React, { useEffect } from 'react';
import PropTypes from 'prop-types'

const TwitterFeed = ({twitterUrl}) => {
    const twitterHandle = twitterUrl.substring(twitterUrl.lastIndexOf('/') + 1);

	useEffect(() => {
		const script = document.createElement('script');
		script.src = "https://platform.twitter.com/widgets.js";
		script.async = true;
		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		}
	}, [])

	return (
		<div className="twitter-feed border rounded-lg overflow-hidden">
			<a 
				className="twitter-timeline" 
				data-height="600" 
				data-theme="light" 
				href={twitterUrl}
			>
				Tweets by {twitterHandle}
			</a>
		</div>
	);
}

TwitterFeed.propTypes = {
	twitterUrl: PropTypes.string.isRequired,
}

export default TwitterFeed;