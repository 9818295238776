import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useForm } from 'react-hook-form';
import { FcGoogle } from 'react-icons/fc';
import { IoLogoTwitter } from 'react-icons/io';
import logo from '../../assets/nav_logo.svg';
import sidebar_image from '../../assets/login.png';

const Login = () => {
	const { register, handleSubmit, errors } = useForm();
	const { login, loginWithGoogle, loginWithTwitter } = useAuth();
	const [error, setError] = useState('');
	const history = useHistory();

	const handleGoogleClick = async () => {
		try {
			await loginWithGoogle();
			history.push('/dashboard');
		} catch {
			setError('Failed to log in');
		}
	}

	const handleTwitterClick = async () => {
		try {
			await loginWithTwitter();
			history.push('/dashboard');
		} catch {
			setError('Failed to log in');
		}
	}

	const onSubmit = async (data) => {
		try {
			await login(data.email, data.password);
			history.push('/dashboard');
		} catch {
			setError('Failed to log in');
		}
	}

	return (
		<div className="flex flex-row w-full">
			<div className="sidebar hidden lg:flex flex-col bg-secondary-beige w-1/3 py-16 px-16 xl:px-32">
				<Link to="/" className="logo text-xl font-medium">
					<img src={logo} alt="logo" className="h-10 mb-16"/>
				</Link>
				<div className="my-auto">
					<h3 className="mb-8">Meow!<br/>Welcome Back</h3>
					<div className="body-1 flex flex-col space-y-4 mb-16">
					We’re so excited to help you get your book out into the world.
					</div>
					<img src={sidebar_image} alt="cat" className="mx-auto"/>
				</div>
			</div>

			<div className="login flex flex-grow">
				<div className="m-auto flex flex-col space-y-8 px-8 p-16 w-full md:p-16 md:w-112">
					<Link to="/" className="logo flex justify-center mb-4 lg:hidden">
						<img src={logo} alt="logo" className="h-14"/>
					</Link>

					<button onClick={handleGoogleClick} className="secondary large w-full relative">
						<FcGoogle size="32px" className="absolute left-6"/>
						<span className="font-normal">Continue with Google</span>
					</button>
					<button onClick={handleTwitterClick} className="secondary large w-full relative">
						<IoLogoTwitter size="32px" color="#1DA1F2" className="absolute left-6"/>
						<span className="font-normal">Continue with Twitter</span>
					</button>

					<div className="divider flex flex-row justify-center items-center">
						<div className="line flex-grow bg-gray-200 h-0.5 rounded-full"></div>
						<span className="mx-2 text-md font-bold text-gray-400">OR</span>
						<div className="line flex-grow bg-gray-200 h-0.5 rounded-full"></div>
					</div>

					<form className="flex flex-col space-y-6" onSubmit={handleSubmit(onSubmit)}>
						<div className="fields space-y-4">
							<div className="form-input">
								<label>Email</label>
								<input 
									type='text' 
									name='email' 
									ref={register({ 
										required: 'Email is required', 
										pattern: {
											value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
											message: 'Invalid email format'
										} 
									})} 
								/>
								{ errors.email && <p className="error">{errors.email.message}</p> }
							</div>
							<div className="form-input">
								<div className="flex justify-between">
									<label>Password</label>
									<Link to="/forgot-password" tabIndex="3" className="text-sm underline">Forgot your password?</Link>
								</div>
								<input 
									type='password' 
									name='password' 
									ref={register({ required: 'Password is required' })} 
								/>
								{ errors.password && <p className="error">{errors.password.message}</p> }
							</div>
						</div>
						<button type="submit" className="primary w-full">Log in</button>
					</form>

					{ error && <div className="error-message flex items-center p-4 text-red-800 bg-red-100 border border-red-200 rounded-md">{error}</div> }

					<div className="flex flex-row justify-center">
						<span>Don't have an account?</span> 
						<button className="link ml-2">
							<Link to="/signup">Sign Up</Link>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Login;