import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useForm } from 'react-hook-form';
import logo from '../../assets/nav_logo.svg';
import sidebar_image from '../../assets/login.png';
import { IoIosArrowBack } from 'react-icons/io';

const ForgotPassword = () => {
	const { register, handleSubmit, errors } = useForm();
	const { resetPassword } = useAuth();
	const [message, setMessage] = useState('');

	const onSubmit = async (data) => {
		try {
			await resetPassword(data.email);
		} catch {
		}

		setMessage('A password reset email has been sent');
	}

	return (
		<div className="flex flex-row w-full">
			<div className="sidebar hidden lg:flex flex-col bg-secondary-beige w-1/3 py-16 px-16 xl:px-32">
				<Link to="/" className="logo text-xl font-medium">
					<img src={logo} alt="logo" className="h-10 mb-16"/>
				</Link>
				<div className="my-auto">
					<h3 className="mb-8">Meow!<br/>Welcome Back</h3>
					<div className="body-1 flex flex-col space-y-4 mb-16">
					We’re so excited to help you get your book out into the world.
					</div>
					<img src={sidebar_image} alt="cat" className="mx-auto"/>
				</div>
			</div>

			<div className="forgot-password flex flex-grow relative">
				<Link to="/login">
					<button className="outline circle large absolute top-8 left-8">
						<IoIosArrowBack size="30px" className="-ml-1"/>
					</button>
				</Link>

				<div className="m-auto flex flex-col space-y-8 px-8 p-16 w-full md:p-16 md:w-128">
					<div className="space-y-4">
						<h5>Forgot Password?</h5>
						<p>Enter the email address you used when you joined and we’ll send you instructions to reset your password.</p>
					</div>
					<form className="flex flex-col space-y-6" onSubmit={handleSubmit(onSubmit)}>
						<div className="fields space-y-4">
							<div className="form-input">
								<label>Email</label>
								<input 
									type='text' 
									name='email' 
									ref={register({ 
										required: 'Email is required', 
										pattern: {
											value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
											message: 'Invalid email format'
										} 
									})} 
								/>
								{ errors.email && <p className="error">{errors.email.message}</p> }
							</div>
						</div>
						<button type="submit" className="primary w-full">Reset Password</button>
					</form>

					{ message && <div className="message flex items-center p-4 text-green-800 bg-green-100 border border-green-200 rounded-md">{message}</div> }

					<div className="flex flex-row justify-center">
						<span>Don't have an account?</span> 
						<button className="link ml-2">
							<Link to="/signup">Sign Up</Link>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ForgotPassword;