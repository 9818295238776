import React, { useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import ReactSelect from 'react-select/creatable';
import ReactQuill from 'react-quill';
import { parseISO } from 'date-fns';
import agentAPI from '../../api/agentAPI';
import DeleteButton from '../DeleteButton';

const defaultAgent = {
	name: '',
	email: '',
	agency: '',
	agency_website: '',
	address: '',
	country: '',
	is_aar_member: false,
	twitter: '',
	twitter_image: '',
	bio: '',
	notes: '',
	submission_guidelines: '',
	submission_link: '',
	querymanager: '',
	fiction_genres: [],	
	non_fiction_genres: [],
	special_interests: '',
	not_representing: '',
	is_accepting_submissions: false,
	is_accepting_snail_mail: false,
	is_accepting_email: false,
	is_accepting_online_form: false,
	clients: [],
}

const EditAgent = () => {
	const { register, handleSubmit, control, errors } = useForm();
	const history = useHistory();
	const { id } = useParams();
	const [agent, setAgent] = useState(null);
	const [genreOptions, setGenreOptions] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isSaving, setIsSaving] = useState(false);
	const isCreating = id ? false : true;

	useEffect(() => {
		const fetchAgent = async () => {
			try {
				const agent = await agentAPI.fetchById(id);
				setAgent(agent);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		}

		const getGenres = async () => {
			try {
				const genres = await agentAPI.fetchGenres();
				setGenreOptions(genres.map(genre => genre._id));
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		}

		if (isCreating) {
			setAgent(defaultAgent);
			setIsLoading(false);
		} else {
			fetchAgent();
		}
		getGenres();

	}, [id, isCreating])

	const onSubmitCreate = async (data) => {
		try {
			setIsSaving(true);
			await agentAPI.addAgent(data);
			history.push('/admin');
		} catch (error) {
			console.log(error);
		}
		setIsSaving(false);
	}

	const onSubmitEdit = async (data) => {
		try {
			setIsSaving(true);
			await agentAPI.updateAgent(agent._id, data);
			history.push('/admin');
		} catch (error) {
			console.log(error);
		}
		setIsSaving(false);
	}

	const deleteAgent = async () => {
		try {
			await agentAPI.deleteAgent(agent._id);
			history.push('/admin');
		} catch (error) {
			console.log(error);
		}
	}

	if (isLoading) {
		return <div className="container p-16">Loading...</div>
	}
	else if (isSaving) {
		return <div className="container p-16">Saving agent...</div>
	} 
	else {
		return (
			<div className="container py-12 md:py-16 px-8 xl:px-32 2xl:px-48">
				<form onSubmit={e => e.preventDefault()} className="space-y-16" >
					<div className="header flex justify-between items-center">
						<div className="flex flex-col">
							<Link to="/admin" className="mb-2 text-gray-500 hover:underline">Admin Panel</Link>
							{ isCreating ? (
								<h4>Create Agent</h4>
							) : (
								<h4>{agent.name}</h4>
							)}
						</div>

						{ isCreating ? (
							<div className="flex items-center space-x-4">
								<button type="button" className="primary" onClick={handleSubmit(onSubmitCreate)}>Create Agent</button>
							</div>
						) : (
							<div className="flex items-center space-x-4">
								<span className="text-gray-500">{ `Last updated ${agent.updatedAt ? parseISO(agent.updatedAt).toDateString() : ''}`}</span>
								<DeleteButton 
									id={agent._id}
									deleteHandler={deleteAgent}
									confirmationMessage="Are you sure? This will permanently delete this agent."
								>
									Delete Agent
								</DeleteButton>
								<button type="button" className="primary" onClick={handleSubmit(onSubmitEdit)}>Update</button>
							</div>
						)}
					</div>

					<div className="section">
						<div className="header mb-6">
							<h6>Personal Information</h6>
							<div className="divider bg-primary w-24 h-1 my-4"></div>
						</div>

						<div className="grid gap-6 grid-cols-3 items-center">
							<div className="form-input">
								<label>Name</label>
								<input type='text' name={'name'} defaultValue={agent.name} placeholder="Full Name" ref={register({ required: true })} />
								{ errors.name && <p className="error">Cannot be blank</p> }
							</div>

							<div className="form-input">
								<label>Agency</label>
								<input type='text' name={'agency'} defaultValue={agent.agency} ref={register({ required: true })} />
								{ errors.agency && <p className="error">Cannot be blank</p> }
							</div>

							<div className="form-input">
								<label>Query Button</label>
								<input type='text' name={'query_button_link'} defaultValue={agent.query_button_link} placeholder="Link or Email" ref={register} />
							</div>

							<div className="form-input">
								<label>Location</label>
								<select name="country" defaultValue={agent.country} ref={register}>
									<option value="Australia">Australia</option>
									<option value="Canada">Canada</option>
									<option value="United Kingdom">United Kingdom</option>
									<option value="United States">United States</option>
									<option value="">None</option>
								</select>
							</div>
							
							<div className="checkbox mt-4"> 
								<label>
									<input type="checkbox" name="is_accepting_submissions" defaultChecked={agent.is_accepting_submissions} ref={register} />
									<span className="font-medium">Accepting Submissions</span>
								</label>
							</div>

							<div className="checkbox mt-4"> 
								<label>
									<input type="checkbox" name="is_aar_member" defaultChecked={agent.is_aar_member} ref={register} />
									<span className="font-medium">AAR Member</span>
								</label>
							</div>
						</div>	
					</div>
					
					<div className="section">
						<div className="header mb-6">
							<h6>Important Links</h6>
							<div className="divider bg-primary w-24 h-1 my-4"></div>
						</div>

						<div className="grid gap-6 grid-cols-3">
							<div className="form-input">
								<label>Email</label>
								<input type='text' name={'email'} defaultValue={agent.email} ref={register} />
							</div>

							<div className="form-input">
								<label>Submission Link</label>
								<input type='text' name={'submission_link'} defaultValue={agent.submission_link} ref={register} />
							</div>

							<div className="form-input">
								<label>QueryManager</label>
								<input type='text' name={'querymanager'} defaultValue={agent.querymanager} ref={register} />
							</div>
							
							<div className="form-input">
								<label>Twitter</label>
								<input type='text' name={'twitter'} defaultValue={agent.twitter} placeholder="https://twitter.com/twitter_handle" ref={register} />
							</div>

							<div className="form-input">
								<label>Agency URL</label>
								<input type='text' name={'agency_website'} defaultValue={agent.agency_website} ref={register} />
							</div>
						</div>	
					</div>
					
					<div className="section">
						<div className="header mb-6">
							<h6>Personal Information</h6>
							<div className="divider bg-primary w-24 h-1 my-4"></div>
						</div>

						<div className="space-y-6">
							<div className="form-input">
								<label>Bio</label>
								<Controller
									name="bio"
									as={ReactQuill}
									control={control}
									theme="snow"
									defaultValue={agent.bio}
									preserveWhitespace
								/>
							</div>

							<div className="form-input">
								<label>Authors Repped</label>
								<Controller
									name="clients"
									as={ReactSelect}
									control={control}
									className="select"
									classNamePrefix="select"
									components={{IndicatorsContainer : () => null, Menu : () => null}}
									isMulti
									isClearable={false}
									defaultValue={agent.clients}
									getOptionValue={option => option}
									getNewOptionData={option => option}
									formatOptionLabel={option => option.__isNew__ ? option.label : option}
								/>
							</div>

							<div className="form-input">
								<label>Books Sold</label>
								<Controller
									name="books_sold"
									as={ReactSelect}
									control={control}
									className="select"
									classNamePrefix="select"
									components={{IndicatorsContainer : () => null, Menu : () => null}}
									isMulti
									isClearable={false}
									defaultValue={agent.books_sold}
									getOptionValue={option => option}
									getNewOptionData={option => option}
									formatOptionLabel={option => option.__isNew__ ? option.label : option}
									placeholder="Add..."
								/>
							</div>

							<div className="form-input">
								<label>Submission Guidelines</label>
								<Controller
									name="submission_guidelines"
									as={ReactQuill}
									control={control}
									theme="snow"
									defaultValue={agent.submission_guidelines}
									preserveWhitespace
								/>
							</div>

							<div className="form-input">
								<label>Fiction Genres</label>
								<Controller
									name="fiction_genres"
									as={ReactSelect}
									control={control}
									className="select"
									classNamePrefix="select"
									isMulti
									isClearable={false}
									options={genreOptions}
									defaultValue={agent.fiction_genres}
									getOptionValue={option => option}
									getNewOptionData={option => option}
									formatOptionLabel={option => option.__isNew__ ? option.label : option}
								/>
							</div>	

							<div className="form-input">
								<label>Non Fiction Genres</label>
								<Controller
									name="non_fiction_genres"
									as={ReactSelect}
									control={control}
									className="select"
									classNamePrefix="select"
									isMulti
									isClearable={false}
									options={genreOptions}
									defaultValue={agent.non_fiction_genres}
									getOptionValue={option => option}
									getNewOptionData={option => option}
									formatOptionLabel={option => option.__isNew__ ? option.label : option}
								/>
							</div>

							<div className="form-input">
								<label>Looking For</label>
								<Controller
									name="special_interests"
									as={ReactQuill}
									control={control}
									theme="snow"
									defaultValue={agent.special_interests}
									preserveWhitespace
								/>
							</div>

							<div className="form-input">
								<label>Not Looking For</label>
								<Controller
									name="not_representing"
									as={ReactQuill}
									control={control}
									theme="snow"
									defaultValue={agent.not_representing}
									preserveWhitespace
								/>
							</div>
						</div>	
					</div>
				</form>
			</div>
		)
	}	
}

export default EditAgent;