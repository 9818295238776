import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { IoMdClose } from 'react-icons/io';
import { useAuth } from '../../contexts/AuthContext';

const AddPrivateQueryButton = ({ addQueryHandler, listId }) => {
	const { currentUser } = useAuth();
	const [modalIsOpen, setIsOpen] = useState(false);
	const { register, handleSubmit, errors } = useForm();

	const onSubmit = (data) => {
		const privateQuery = { 
			privateAgentName: data.privateAgentName, 
			privateAgentURL: data.privateAgentURL
		};

		addQueryHandler(privateQuery, listId);
		handleClose();
	}
	
	const handleOpen = () => {
		if (currentUser) {
			setIsOpen(true);
		}
		else {
			toast((t) => (
				<div className="flex items-center space-x-4 -mr-2">
					<div className="content space-y-2">
						<h6>Sign Up to Save Agents</h6>
						<div className="upgrade-note space-y-2">
							<span className="body-2">You need an account to save agents</span>
						</div>
					</div>
					<Link to="/signup" onClick={() => toast.dismiss(t.id)}><button className="primary">Sign up</button></Link>
					<IoMdClose size="24px" className="cursor-pointer hover:text-gray-700 transition" onClick={() => toast.dismiss(t.id)} />
				</div>
			))
		}
	}

	const handleClose = () => {
		setIsOpen(false);
	}

	return (
		<div className="edit-list">
			<button className="link" onClick={handleOpen}>
				Add Private Agent
			</button>

			<Modal
				className="modal"
				overlayClassName="modal-overlay"
				isOpen={modalIsOpen}
				contentLabel="Add Private Agent"
				onRequestClose={handleClose}
			>
				<form onSubmit={handleSubmit(onSubmit)} >
					<header className="flex justify-between items-center p-4 border-b">
						<h6>Add Private Agent</h6>
						<IoMdClose size="24px" className="cursor-pointer hover:text-gray-700 transition" onClick={handleClose} />
					</header>
					<div className="content p-8 w-full">
						<div className="flex flex-col space-y-4 body-1 md:w-96">
							<p className="body-1">This agent does not exist in QueryCat and is for your use only. No one else has access to this agent.</p>
							<div className="form-input">
								<label>Agent Name</label>
								<input 
									type='text' 
									name='privateAgentName'
									autoComplete='off'
									placeholder='John Scully'
									ref={register({ required: 'Agent name is required' })} 
								/>
								{ errors.privateAgentName && <p className="error">{errors.privateAgentName.message}</p> }
							</div>
							<div className="form-input">
								<label>Website</label>
								<input 
									type='text'
									name='privateAgentURL'
									autoComplete='off'
									placeholder='https://agentwebsite.com'
									ref={register({
										pattern: {
											value: /https:\/\/.*/,
											message: 'Make sure your link has "https://" before the address'
										} 
									})} 
								/>
								{ errors.privateAgentURL && <p className="error">{errors.privateAgentURL.message}</p> }
							</div>
						</div>
					</div>
					<footer className="flex justify-between p-4 border-t">
						<button type="button" className="link ml-2" onClick={handleClose}>Cancel</button>
						<button type="submit" className="primary">Add</button>
					</footer>
				</form>
			</Modal>
		</div>
	);
}

export default AddPrivateQueryButton;