import * as requests from './commonAPI';

// Used to limit the number of agents returned for pagination
const parseLimit = (count, p) => `limit=${count}&offset=${p ? p * count : 0}`;
const parseSearch = (searchTerm) => searchTerm ? `&search=${searchTerm}` : '';
const parseFilters = (filters) => {
	var params = '';
	for (var key in filters) {
		if (filters[key])
			params += `&${key}=${filters[key]}`;
	};
	return params;
}

const Agents = {
	fetchPage: (page, searchTerm, filters) => 
		requests.get(`/agents?${parseLimit(30, page)}${parseSearch(searchTerm)}${parseFilters(filters)}`),
	fetchAll: () => 
		requests.get(`/agents/all`),
	fetchFeatured: () => 
		requests.get(`/agents/featured`),
	fetchByName: (name) => 
		requests.get(`/agents/${name}`),
	fetchById: (id) => 
		requests.get(`/agents/id/${id}`),
	fetchSimilar: (id, limit) => 
		requests.get(`/agents/similar/${id}?${parseLimit(limit)}`),
	fetchGenres: () => 
		requests.get(`/agents/genres`),
	fetchSaved: () => 
		requests.get(`/agents/saved`),
	saveToList: (agentId, listId) =>
		requests.post(`/agents/${agentId}/save`, { listId }),
	removeFromList: (agentId, listId) =>
		requests.post(`/agents/${agentId}/unsave`, { listId }),
	addAgent: (agentData) => 
		requests.post(`/agents`, { agentData }),
	deleteAgent: (agentId) => 
		requests.del(`/agents/${agentId}`),
	updateAgent: (agentId, updatedAgent) => 
		requests.put(`/agents/${agentId}/update`, { updatedAgent }),
}

export default Agents;