import React, { useEffect } from 'react';
import ContentLoader from 'react-content-loader';
import { Accordion } from 'react-accessible-accordion';
import EmptyState from '../EmptyState';
import List from '../Lists/List';
import NewListButton from '../Lists/NewListButton';
import { useLists } from '../../contexts/ListsContext';

const Lists = () => {
	const { lists, isListsLoading, fetchLists } = useLists();

	useEffect(() => {
		fetchLists();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className="container">
			{ isListsLoading ? (
				<div className="wrapper">
					<div className="flex items-center justify-between mb-6">
						<h4>Your Lists</h4>
					</div>
					<div className="divider bg-primary w-24 h-1 mb-4"></div>
					<ContentLoader 
						speed={2}
						width={"100%"}
						viewBox="0 0 620 200"
						backgroundColor="#F5F7FA"
						foregroundColor="#E4E7EB"
					>
						<circle cx="31" cy="31" r="15" /> 
						<rect x="58" y="18" rx="2" ry="2" width="522" height="10" /> 
						<rect x="58" y="34" rx="2" ry="2" width="140" height="10" /> 
						<circle cx="31" cy="82" r="15" /> 
						<rect x="58" y="69" rx="2" ry="2" width="522" height="10" /> 
						<rect x="58" y="85" rx="2" ry="2" width="140" height="10" /> 
						<circle cx="31" cy="135" r="15" /> 
						<rect x="58" y="122" rx="2" ry="2" width="522" height="10" /> 
						<rect x="58" y="138" rx="2" ry="2" width="140" height="10" />
					</ContentLoader>
				</div>
			) : (
				<div className="lists">
					<div className="flex items-center justify-between mb-6">
						<h4>Your Lists</h4>
						<NewListButton />
					</div>

					<div className="divider bg-primary w-24 h-1 mb-4"></div>

					{ lists.length ? (
						<Accordion 
                            allowMultipleExpanded 
                            allowZeroExpanded  
                            preExpanded={[lists[0]._id]} 
                            className="accordion lists-accordion"
                        >
							{ lists.map(list => 
								<List 
									key={list._id} 
									listProp={list}
								/>
							)}
						</Accordion>
					) : (
						<EmptyState
							primaryText="Looks like you don't have any lists"
							secondaryText={<div>You can create one by clicking the <span className="font-bold">"+ New List"</span> button above.</div>}
						/>
					)}
				</div>
			)}
		</div>
	);
}

export default Lists;