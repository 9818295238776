import React from 'react';
import { toast } from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';

const ActionToast = ({type, toastId, response}) => {
	return (
		<div className="flex items-center space-x-4 ml-4 -mr-2">
			<div className="content space-y-2">
				<h6>{ response.title }</h6>
				<span className="body-2">{response.message}</span>

				{/* { !isUserPremium &&
					<div className="upgrade-note space-y-2">
						<span className="body-2">{response.message}</span>
						<button className="link">
							<Link to="/about/pricing" onClick={() => toast.dismiss(toastId)}>Upgrade to premium</Link>
						</button>
					</div>
				} */}
			</div>
			<IoMdClose size="24px" className="cursor-pointer hover:text-gray-700 transition" onClick={() => toast.dismiss(toastId)} />
		</div>
	)
}

export default ActionToast;