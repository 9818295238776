import React from 'react';
import EmptyState from '../EmptyState';
import Query from './Query';

const QueriesTable = ({ listProp, deleteHandler }) => {
	return (
		<div className="container">
			<div className="header hidden lg:flex bg-primary bg-opacity-5">
				<div className="agent-name p-4 w-full lg:w-1/5">
					<h6>Agent</h6>
				</div>
				<div className="query-date px-2 p-4 w-1/2 lg:w-1/5">
					<h6>Date Queried</h6>
				</div>
				<div className="query-status px-2 p-4 w-1/2 lg:w-1/5">
					<h6>Status</h6>
				</div>
				<div className="notes px-2 p-4 w-full lg:w-1/5">
					<h6>Notes</h6>
				</div>
			</div>
			{ 
				listProp.queries.length ? (
					<div className="queries space-y-4">
						{
							listProp.queries.map(query => {
								return (
									<Query 
										key={query._id} 
										query={query}
										deleteHandler={deleteHandler}
									/>
								);
							})
						}
					</div>
				) : (
					<EmptyState
						primaryText="No saved agents"
						secondaryText="You can save agents to this list to keep track of your queries"
					/>
				)
			}
		</div>
	)
}

export default QueriesTable;