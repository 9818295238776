import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { parseISO } from 'date-fns';
import agentAPI from '../../api/agentAPI';

const AllAgents = () => {
	const [agents, setAgents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchAgents = async () => {
			try {
				const agents = await agentAPI.fetchAll();
				setAgents(agents);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		}

		fetchAgents();
	}, [])

	if (isLoading) {
		return (
			<div className="container p-16">Loading agents...</div>
		)
	} else {
		return (
			<div className="container p-16">
				<div className="flex items-center justify-between mb-6">
					<h4>Admin Panel</h4>
					<Link to="/admin/add-agent"><button className="tertiary truncate">+ Agent</button></Link>
				</div>

				<div className="header hidden lg:flex bg-primary bg-opacity-5">
					<div className="query-date p-4 w-1/4">
						<h6>Agency</h6>
					</div>
					<div className="agent-name p-4 w-1/4">
						<h6>Agent</h6>
					</div>
					<div className="query-status p-4 w-1/4">
						<h6>Date Updated</h6>
					</div>
					<div className="notes p-4 w-1/4">
						<h6>Open to Submissions</h6>
					</div>
				</div>

				{ agents.map(agent => 
					<Link 
						to={`/admin/agent/${agent._id}`}
						className="agent flex border-t border-gray-200 bg-white hover:bg-gray-50"
						key={agent._id}
					>
						<div className="query-date p-4 w-1/4">
							{ agent.agency }
						</div>
						<div className="agent-name p-4 w-1/4">
							{ agent.name }
						</div>
						<div className="query-status p-4 w-1/4">
							{ agent.updatedAt ? parseISO(agent.updatedAt).toDateString() : '' }
						</div>
						<div className="notes p-4 w-1/4">
							{ agent.is_accepting_submissions ? 'Yes' : 'No' }
						</div>
					</Link>
				)}
			</div>   
		)
	}	
}

export default AllAgents;