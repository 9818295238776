import React from 'react';
import PropTypes from 'prop-types'
import { FiTwitter } from 'react-icons/fi';
import { HiOutlineMail, HiOutlineLink } from 'react-icons/hi';
import { getValidUrl } from '../../utils/urls';

const Links = ({agent}) => {
	const twitterHandle = agent.twitter.substring(agent.twitter.lastIndexOf('/') + 1);

	return (
		<div className="
			links-section px-8 py-6 space-y-6
			bg-gray-100 border border-gray-200 rounded-lg
			text-gray-900 subtitle-1
		">
			<h6>Important Links</h6>
			{ agent.email &&
				<a href={`mailto:${agent.email}`} className="link flex space-x-4">
					<HiOutlineMail size="24px" />
					<span className="underline truncate">{agent.email}</span>
				</a>
			}
			{ agent.twitter &&
				<a href={agent.twitter} target="_blank" rel='noreferrer' className="link flex space-x-4">
					<FiTwitter size="24px" />
					<span className="underline truncate">@{twitterHandle}</span>
				</a>
			}
			{ agent.agency_website &&
				<a href={getValidUrl(agent.agency_website)} target="_blank" rel='noreferrer' className="link flex space-x-4">
					<HiOutlineLink size="24px" />
					<span className="underline truncate">{agent.agency_website}</span>
				</a>
			}
			{ agent.querymanager &&
				<a href={getValidUrl(agent.querymanager)} target="_blank" rel='noreferrer' className="link flex space-x-4">
					<HiOutlineLink size="24px" />
					<span className="underline">Query Manager</span>
				</a>
			}
			{ agent.submission_link &&
				<a href={getValidUrl(agent.submission_link)} target="_blank" rel='noreferrer' className="link flex space-x-4">
					<HiOutlineLink size="24px" />
					<span className="underline">Submission Link</span>
				</a>
			}
		</div>
		// currentUser ? (
		//     CONTENT
		// ) : (
		//     <div className="
		//         links-section px-8 py-6 space-y-6
		//         bg-gray-100 border border-gray-200 rounded-lg 
		//         text-gray-900 subtitle-1
		//     ">
		//         <h6>Important Links</h6>
		//         <p>Meow! This area’s only available if you have an account.</p>
		//         <button className="link">
		//             <Link to="/signup">Sign Up/Log in</Link>
		//         </button>
		//     </div>
		// )
	);
}

Links.propTypes = {
	agent: PropTypes.object.isRequired,
}

export default Links;