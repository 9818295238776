import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ContentLoader from 'react-content-loader';
import ReactPaginate from 'react-paginate';
import Select from 'react-select';
import useDocumentTitle from '../hooks/useDocumentTitle';
import agentAPI from '../api/agentAPI';
import AgentCard from './AgentCard';
import AgentLoader from './AgentLoader';

// COULDDO: fetch these dynamically from the db similar to genres
const countryOptions = [
	{ value: 'Australia', label: 'Australia' },
	{ value: 'Canada', label: 'Canada' },
	{ value: 'United Kingdom', label: 'United Kingdom' },
	{ value: 'United States', label: 'United States' }
]

const SearchPage = () => {
	const [agents, setAgents] = useState([]);
	const [agentsCount, setAgentsCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);

	const [page, setPage] = useState(0);
	const [pageCount, setPageCount] = useState(0);
	const pageLimit = 30;

	const query = new URLSearchParams(useLocation().search).get('search');
	const [twitterFilter, setTwitterFilter] = useState(false);
	const [submissionFilter, setSubmissionFilter] = useState(false);
	const [locationFilter, setLocationFilter] = useState('');

	useDocumentTitle(query ? query : 'All Agents');

	useEffect(() => {
		const fetchAgents = async () => {
			try {
				const data = await agentAPI.fetchPage(
					page, 
					query, 
					{
						hasTwitter: twitterFilter,
						isSeekingSubmissions: submissionFilter,
						locationFilter: locationFilter
					}
				);
				setAgents(data.agents);
				setAgentsCount(data.agentsCount);
				setPageCount(Math.ceil(data.agentsCount / pageLimit));
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		}

		fetchAgents();
	}, [page, query, twitterFilter, submissionFilter, locationFilter])

	const handleLocationFilter = (option, action) => {
		if (action.action === "clear") {
			setLocationFilter('');
		}
		else {
			setLocationFilter(option.value);
		}
	}

	const handlePageChange = (page) => {
		setPage(page);
		window.scrollTo(0, 0);
	}

	const renderAgentLoaders = () => {
		var agentLoaders = [];
		for (let index = 0; index < 12; index++) {
			agentLoaders.push(<AgentLoader key={index} />)
		}
		return agentLoaders;
	}

	if (isLoading) {
		return (
			<div className="container py-16 px-8 xl:px-32 2xl:px-48">
				<ContentLoader 
					speed={2}
					width={476}
					height={156}
					viewBox="0 0 476 156"
					backgroundColor="#F5F7FA"
					foregroundColor="#E4E7EB"
					className="mb-12"
				>
					<rect x="0" y="0" rx="6" ry="6" width="118" height="20" /> 
					<rect x="0" y="40" rx="6" ry="6" width="301" height="44" /> 
					<rect x="0" y="116" rx="17" ry="17" width="104" height="34" /> 
					<rect x="122" y="116" rx="17" ry="17" width="138" height="34" /> 
					<rect x="276" y="116" rx="17" ry="17" width="166" height="34" />
				</ContentLoader>
				<div className="agent-list grid gap-4 grid-cols-1 lg:grid-cols-3 mb-8">
					{renderAgentLoaders()}
				</div>
			</div>
		)
	} else {
		return (
			<div className="container py-12 lg:py-16 px-8 xl:px-16 2xl:px-48">

				<h6 className="mb-2">{agentsCount} agents</h6>

				{query ? (
					<h4 className="mb-8">Agents matching "{query}"</h4>  
				 ) : (
					<h4 className="mb-8">All agents</h4> 
				)}

				<div className="filters flex flex-wrap mb-4 lg:mb-8">
					<button 
						className={`toggle transition mr-4 mb-4 ${twitterFilter ? " active" : ""}`}
						onClick={() => setTwitterFilter(!twitterFilter)}
					>
						Has Twitter
					</button>
					<button 
						className={`toggle transition mr-4 mb-4 ${submissionFilter ? " active" : ""}`}
						onClick={() => setSubmissionFilter(!submissionFilter)}
					>
						Seeking Submissions
					</button>
					<div className="location-filter w-56 mr-4 mb-4">
						<Select
							className="select filter"
							classNamePrefix="select"
							isClearable={true}
							isSearchable={false}
							onChange={handleLocationFilter}
							options={countryOptions}
							placeholder="Location"
						/>
					</div>
				</div>
				
				<div className="agent-list grid gap-4 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 mb-8">
					{
						agents.map(agent => {
							return (
								<AgentCard 
									agent={agent} 
									key={agent._id}
									genreKeyword={query} 
								/>
							);
						})
					}
				</div>

				<ReactPaginate
					pageCount={pageCount}
					marginPagesDisplayed={1}
					pageRangeDisplayed={2}
					onPageChange={(data) => handlePageChange(data.selected)}
					previousLabel={'Previous'}
					nextLabel={'Next'}
					breakLabel={'...'}
					containerClassName={'pagination'}
					activeClassName={'active'}
					breakClassName={'break-me'}
				/>
			</div>
		)
	}	
}

export default SearchPage;