import * as requests from './commonAPI';

const Lists = {
	fetchById: (id) => 
		requests.get(`/lists/${id}`),
	fetchLists: () => 
		requests.get(`/lists`),
	createList: (name) => 
		requests.post(`/lists`, { name }),
	deleteList: (id) => 
		requests.del(`/lists/${id}`),
	updateList: (id, name) => 
		requests.put(`/lists/update/${id}`, { name }),
}

export default Lists;