import React from 'react';
import { useHistory } from 'react-router-dom';
import hero_image from '../../assets/hero.png';

const HeroSection = () => {
	const history = useHistory();

	const onClick = () => {
		history.push('/agents');
	}

	return (
		<div className="
			hero-section container flex flex-col items-center p-8 space-y-10
			lg:flex-row lg:p-16 lg:space-y-0 lg:justify-between
			xl:px-24 2xl:px-48
		">
			<div className="block">
				<h1 className="text-5xl leading-tight font-bold mb-6">Wrote a book? Now let’s<br/> find you an agent</h1>
				<h2 className="text-2xl leading-normal font-medium mb-8">Research <span className="font-bold border-b-4 border-secondary-yellow">1500+ agents</span>, shortlist the ones<br/>you love, and track your queries.</h2>
				<button className="primary" onClick={onClick}>Find an Agent</button>
			</div>
			
			<img src={hero_image} alt="E.J Simon Testimonial" />
		</div>
	);
}

export default HeroSection;