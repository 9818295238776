import * as requests from './commonAPI';

const Queries = {
	addPrivateQuery: (listId, privateQuery) =>
		requests.post(`/queries/${listId}`, { privateQuery }),
	deleteQuery: (id) =>
		requests.del(`/queries/${id}`),
	updateQuery: (id, query) => 
		requests.put(`/queries/${id}/update`, { query }),
}

export default Queries;