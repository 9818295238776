import React from 'react';
import PersonalInfo from './PersonalInfo';

const UserSettingsPage = () => { 
	return (
		<div className="container py-16 px-8 xl:px-32 2xl:px-48">
			<h4 className="mb-8">Settings</h4>
            <PersonalInfo/>
			{/* <Tabs>
				<TabList>
					<Tab>Personal Info</Tab>
					<Tab>Membership</Tab>
				</TabList>

				<TabPanel>
                    <PersonalInfo/>
				</TabPanel>

				<TabPanel>
                    <Membership/>
				</TabPanel>
			</Tabs> */}
		</div>
	);
}

export default UserSettingsPage;