// useDocumentTitle.js
import { useEffect } from 'react'

function useDocumentTitle(title, prevailOnUnmount = false) {
    const defaultTitle = 'QueryCat';

    useEffect(() => {
        document.title = `${title} - ${defaultTitle}`;
    }, [title]);

    useEffect(() => () => {
        if (!prevailOnUnmount) {
            document.title = defaultTitle;
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export default useDocumentTitle
