import React from 'react';
import { useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';

const SecondaryCTASection = () => {
	const history = useHistory();

	const onClickSignUp = () => {
		ReactPixel.track('InitiateCheckout', { content_category : 'home-below-fold' });
		history.push('/signup');
	}

	return (
		<div className="
			cta-section bg-secondary-beige
		">
			<div className="
				container flex flex-col items-center p-12 space-y-6
				lg:p-24 lg:space-y-8
				xl:px-32 2xl:px-48
			">
				<div className="header text-center">
					<h3 className="font-bold mb-4">Find an Agent. Actually Enjoy the Process.</h3>
					<h5>Sounds crazy but we designed QueryCat with authors in mind.</h5>
				</div>

				<button className="primary" onClick={onClickSignUp}>Get Started Today</button>
			</div>
		</div>
	);
}

export default SecondaryCTASection;