import React from "react"
import ContentLoader from "react-content-loader"

const AgentLoader = (props) => (
    <div className="p-6 h-48 border border-gray-100 rounded-xl overflow-hidden">
        <ContentLoader 
            speed={3}
            width={325}
            height={150}
            viewBox="0 0 325 150"
            backgroundColor="#F5F7FA"
            foregroundColor="#E4E7EB"
            {...props}
        >
            <rect x="73" y="4" rx="3" ry="3" width="107" height="12" /> 
            <rect x="73" y="26" rx="3" ry="3" width="196" height="22" /> 
            <rect x="73" y="82" rx="9" ry="9" width="96" height="18" /> 
            <circle cx="28" cy="28" r="28" /> 
            <rect x="181" y="82" rx="9" ry="9" width="132" height="18" /> 
            <rect x="73" y="115" rx="9" ry="9" width="122" height="18" /> 
            <rect x="207" y="115" rx="9" ry="9" width="74" height="18" />
        </ContentLoader>
    </div>
)

export default AgentLoader