import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { toast } from 'react-hot-toast';
import { IoMdHeartEmpty, IoMdHeart, IoMdClose } from 'react-icons/io';
import { useAuth } from '../contexts/AuthContext';
import { useLists } from '../contexts/ListsContext';
import NewListButton from './Lists/NewListButton';
import SaveAgentList from './Lists/SaveAgentList';

const SaveAgentButton = ({agent, size}) => {
	const { currentUser } = useAuth();
	const { lists, isListsLoading, isAgentSaved } = useLists();
	const [modalIsOpen, setIsOpen] = useState(false);
	
	const iconSize = size === 'large' ? '44px' : '32px';

	const handleOpen = () => {
		if (currentUser) {
			setIsOpen(true);
		}
		else {
			toast((t) => (
				<div className="flex items-center space-x-4 -mr-2">
					<div className="content space-y-2">
						<h6>Sign Up to Save Agents</h6>
						<div className="upgrade-note space-y-2">
							<span className="body-2">You need an account to save agents</span>
						</div>
					</div>
					<Link to="/signup" onClick={() => toast.dismiss(t.id)}><button className="primary">Sign up</button></Link>
					<IoMdClose size="24px" className="cursor-pointer hover:text-gray-700 transition" onClick={() => toast.dismiss(t.id)} />
				</div>
			))
		}
	}

	const handleClose = () => {
		setIsOpen(false);
	}

	return (
		<div className="save-agent">
			<div className="cursor-pointer font-medium tracking-wide" onClick={handleOpen}>
				{ isAgentSaved(agent._id) ? (
					<div className="flex flex-col items-center">
						<IoMdHeart size={iconSize} className="text-primary" />
						{size === 'large' && <span>Saved</span>}
					</div>
				) : (
					<div className="flex flex-col items-center">
						<IoMdHeartEmpty size={iconSize} className={"transition-all hover:text-primary " + (size === 'large' ? "text-gray-400" : "text-gray-300")} />
						{size === 'large' && <span>Save</span>}
					</div>
				)}
			</div>

			<Modal
				className="modal"
				overlayClassName="modal-overlay"
				isOpen={modalIsOpen}
				contentLabel="Save to list"
				onRequestClose={handleClose}
			>
				<header className="flex justify-between items-center p-4 border-b">
					<h6>Save to list</h6>
					<IoMdClose size="24px" className="cursor-pointer hover:text-gray-700 transition" onClick={handleClose} />
				</header>
				<div className="content px-6 w-full">
					{ isListsLoading ? (
						<div>Loading lists...</div>
					) : (
						<div className="lists md:w-96 divide-y-1">
							{ lists.length ? (
								lists.map(list =>
									<SaveAgentList 
										key={list._id} 
										list={list} 
										agentId={agent._id}
									/>
								)
							) : (
								<p className="my-4 font-normal">Looks like you don't have a list to save agents to. You can create one by clicking the <span className="font-bold">"+ New List"</span> button below.</p>
							)}
						</div>
					)}
				</div>
				<footer className="flex justify-end p-4 border-t">
					<NewListButton />
				</footer>
			</Modal>
		</div>
	);
}

export default SaveAgentButton;