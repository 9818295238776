import React from 'react';
import { Link } from 'react-router-dom';
import BusinessImage from '../../assets/genres/Business.png';
import HistoryImage from '../../assets/genres/History.png';
import LiteraryImage from '../../assets/genres/Literary.png';
import MysteryImage from '../../assets/genres/Mystery.png';
import RomanceImage from '../../assets/genres/Romance.png';
import ScienceFictionImage from '../../assets/genres/Science Fiction.png';
import ThrillerImage from '../../assets/genres/Thriller.png';
import YoungAdultImage from '../../assets/genres/Young Adult.png';

const featuredGenres = [ 
	{name: 'Business', image: BusinessImage },
	{name: 'History', image: HistoryImage },
	{name: 'Literary', image: LiteraryImage },
	{name: 'Mystery', image: MysteryImage },
	{name: 'Romance', image: RomanceImage },
	{name: 'Science Fiction', image: ScienceFictionImage },
	{name: 'Thriller', image: ThrillerImage },
	{name: 'Young Adult', image: YoungAdultImage },
]

const FeaturedGenres = () => {
	return (
		<div className="featured-genres">
			<div className="flex items-center justify-between mb-4">
				<h4>Search By Genre</h4>
			</div>

			<div className="divider bg-primary w-24 h-1 mb-6"></div>

			<div className="genre-list w-full grid gap-4 grid-cols-2 md:grid-cols-4 md:gap-6">
				{
					featuredGenres.map(genre => {
						return (
							<Link 
								to={`/agents?search=${genre.name}`} 
								className="genre flex flex-col flex-grow justify-center items-center py-6 space-y-2 bg-white card"
								key={genre.name}
							>
								<img src={genre.image} alt={genre.name} className="w-20" />
								<span className="text-center body-1">{genre.name}</span>
							</Link>
						);
					})
				}
			</div>
		</div>
	);
}

export default FeaturedGenres;