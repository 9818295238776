import React from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';
import { useAuth } from '../../contexts/AuthContext';
import { getValidUrl } from '../../utils/urls';

const QueryButton = ({ link }) => {
	const { currentUser } = useAuth();
    const isEmail = link.includes('@');
	
	const handleClick = () => {
		if (currentUser) {
            if (isEmail) {
                window.location = `mailto:${link}`;
            } else {
                window.open(getValidUrl(link));
            }
		} else {
			toast((t) => (
				<div className="flex items-center space-x-4 -mr-2">
					<div className="content space-y-2">
						<h6>Sign Up to Query Agents</h6>
						<div className="upgrade-note space-y-2">
							<span className="body-2">You need an account to query agents</span>
						</div>
					</div>
					<Link to="/signup" onClick={() => toast.dismiss(t.id)}><button className="primary">Sign up</button></Link>
					<IoMdClose size="24px" className="cursor-pointer hover:text-gray-700 transition" onClick={() => toast.dismiss(t.id)} />
				</div>
			))
		}
	}

	return (
		<button className="primary" onClick={handleClick}>Query</button>
	);
}

export default QueryButton;