import React from 'react';
import pluralize from 'pluralize';
import { IoMdCheckmark } from 'react-icons/io';
import { useLists } from '../../contexts/ListsContext';

const SaveAgentList = ({ agentId, list }) => {
	const { saveAgent, unSaveAgent, isAgentInList } = useLists();

	const handleSave = async () => {
		await saveAgent(agentId, list._id)
	}

	const handleRemove = async () => {
		await unSaveAgent(agentId, list._id)
	}

	return (
		<div className="list flex flex-row justify-between items-center py-4">
			<div className="flex flex-col truncate mr-4">
				<span className="body-1 font-medium tracking-wide mb-1 truncate">
					{list.name}
				</span> 
				<span className="body-2">{pluralize('Agent', list.queries.length, true)} Saved</span> 
			</div>
			{ isAgentInList(list, agentId) ? (
				<div className="actions flex space-x-8">
					<div className="flex space-x-1 text-gray-500">
						<IoMdCheckmark size="24px" />
						<span>Saved</span>
					</div>
					<button className="link" onClick={handleRemove}>Remove</button>
				</div>
			) : (
				<div className="actions flex space-x-4">
					<button className="primary" onClick={handleSave}>Save</button>
				</div>
			)}
		</div>
	);
}

export default SaveAgentList;