import { createApi } from 'unsplash-js';

const unsplash = new createApi({
    accessKey: process.env.REACT_APP_UNSPLASH_KEY,
});

const Unsplash = {
	fetchCatImage: () =>
        unsplash.photos.getRandom({
            query: 'cat',
            orientation: 'landscape',
            collections: '1043454',
        })
        .then(res => res.response)
}

export default Unsplash;