import React, { useContext, useState } from 'react';
import ReactGA from 'react-ga';
import { toast } from 'react-hot-toast';
import agentAPI from '../api/agentAPI';
import listAPI from '../api/listAPI';
import queryAPI from '../api/queryAPI';
import ActionToast from '../components/Lists/ActionToast';

const ListsContext = React.createContext()

export function useLists() {
	return useContext(ListsContext)
}

export function ListsProvider({ children }) {
	const [lists, setLists] = useState([]);
	const [isListsLoading, setIsListsLoading] = useState(true);

	const fetchLists = async () => {
		setIsListsLoading(true);
		try {
			const lists = await listAPI.fetchLists();
			setLists(lists);
			setIsListsLoading(false);
		} catch (error) {
			console.log(error);
		}
	}

	const createList = async (name) => {
		try {
			const {newList, response} = await listAPI.createList(name);
			setLists(lists => [...lists, newList]);
			ReactGA.event({ category: 'List', action: 'Created a List' });
			toast.success(t => <ActionToast type='success' toastId={t.id} response={response} />);
		} catch (error) {
			switch (error.response.status) {
				case 403:
					toast.error(t => <ActionToast type='error' toastId={t.id} response={error.response.data} />);
					break;
				default:
					console.log(error)
			}
		}
	}
	
	const deleteList = async (listId) => {
		try {
			await listAPI.deleteList(listId);
			setLists(lists => lists.filter(list => list._id !== listId));
		} catch (error) {
			console.log(error);
		}
	}

	const editList = async (listId, name) => {
		try {
			const newList = await listAPI.updateList(listId, name);
			updateListsState(newList);
		} catch (error) {
			console.log(error.message);
		}
	}

	const saveAgent = async (agentId, listId) => {
		try {
			const {newList, response} = await agentAPI.saveToList(agentId, listId);
			updateListsState(newList);
			ReactGA.event({ category: 'List', action: 'Saved Agent to List' });
			toast.success(t => <ActionToast type='success' toastId={t.id} response={response} />);
		} catch (error) {
			switch (error.response.status) {
				case 403:
					toast.error(t => <ActionToast type='error' toastId={t.id} response={error.response.data} />);
					break;
				default:
					console.log(error)
			}
		}
	}

	const unSaveAgent = async (agentId, listId) => {
		try {
			const newList = await agentAPI.removeFromList(agentId, listId);
			updateListsState(newList);
		} catch (error) {
			console.log(error);
		}
	}

	const isAgentSaved = (agentId) => {
		return lists.find(list => isAgentInList(list, agentId))
	}

	const isAgentInList = (list, agentId) => {
		return list.queries.some(query => {
			if (query.is_private_query) { return false; }
			return query.agent._id.toString() === agentId.toString()
		})
	}

    const addPrivateQuery = async (privateQuery, listId) => {
		try {
			const { updatedList } = await queryAPI.addPrivateQuery(listId, privateQuery);
			updateListsState(updatedList);
		} catch (error) {
			console.log(error);
		}
	}

	const deleteQuery = async (queryId) => {
		try {
			const updatedList = await queryAPI.deleteQuery(queryId);
			updateListsState(updatedList);
		} catch (error) {
			console.log(error);
		}
	}

	// Update the current lists state with new list
	const updateListsState = (newList) => {
		const newLists = lists.map(list => {
			if (list._id === newList._id) {return newList;}
			return list;
		});
		setLists(newLists);
	}

	const value = {
		lists,
		isListsLoading,
		fetchLists,
		createList,
		deleteList,
		editList,
		saveAgent,
		unSaveAgent,
		isAgentSaved,
		isAgentInList,
        addPrivateQuery,
		deleteQuery
	}

	return (
		<ListsContext.Provider value={value}>
			{children}
		</ListsContext.Provider>
	)
}