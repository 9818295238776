import { useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import { LiveChatWidget } from '@livechat/widget-react'

import AdminRoute from './Routes/AdminRoute';
import PrivateRoute from './Routes/PrivateRoute';

import AdminAgents from './Admin/AllAgents';
import EditAgent from './Admin/EditAgent';
import AgentProfile from './AgentProfile/AgentProfile';
import Footer from './Footer';
import ForgotPassword from './Authentication/ForgotPassword';
import Home from './Home/Home';
import Login from './Authentication/Login';
import Dashboard from './Dashboard/Dashboard';
import Nav from './Nav';
import SearchPage from './SearchPage';
import PrivacyPolicy from './Authentication//PrivacyPolicy';
import SignUp from './Authentication/SignUp';
import Terms from './Authentication//Terms';
import UserSettings from './UserSettings/UserSettingsPage';

const App = () => {
	const { pathname } = useLocation();
	const showNav = 
		pathname !== '/login' && 
		pathname !== '/signup' && 
		pathname !== '/signup/payment-details' && 
		pathname !== '/forgot-password';

	// Initialize
	useEffect(() => {
		// Google Analytics Setup
		const googleTrackingId = process.env.REACT_APP_GA_TRACKING_ID;
		ReactGA.initialize(googleTrackingId);

		// Facebook Pixel Tracking Setup
		const facebookTrackingId = process.env.REACT_APP_FB_PIXEL_ID;
		ReactPixel.init(facebookTrackingId);
	}, [])

	// Page changed, send to analytics
	useEffect(() => {
		ReactGA.set({ page: pathname });    // Update the user's current page
		ReactGA.pageview(pathname);         // Record a pageview for the given page
		ReactPixel.pageView();
	}, [pathname])

	return (
		<div id="main" className="flex flex-col min-h-screen">

			{ showNav && <Nav /> }

			<div className={"content flex flex-grow " + (showNav ? "mt-36 lg:mt-24" : "") }>
				<Switch>
					<AdminRoute path="/admin" exact component={AdminAgents} />
					<AdminRoute path="/admin/agent/:id" exact component={EditAgent} />
					<AdminRoute path="/admin/add-agent" exact component={EditAgent} />
					<Route path="/forgot-password" exact component={ForgotPassword} />
					<Route path="/login" exact component={Login} />
					<Route path="/signup" exact component={SignUp} />
					<Route path="/about/privacy" exact component={PrivacyPolicy} />
					<Route path="/about/terms" exact component={Terms} />
					<PrivateRoute path="/settings" exact component={UserSettings} />
					<PrivateRoute path="/dashboard" exact component={Dashboard} />
					<Route path="/agents" exact component={SearchPage} />
					<Route path="/agent/:name" component={AgentProfile} />
					<Route path="/" exact component={Home} />
				</Switch>
			</div>

			{ showNav && <Footer /> }

			<Toaster 
				position='bottom-left'
				toastOptions={{
					duration: 8000,
					style: {
						maxWidth: '500px',
						padding: '16px 24px',
					}
				}}
			/>

			<LiveChatWidget
				license="13772160"
				visibility="minimized"
			/>
		</div>
	)
}

export default App;