import React, { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import Avatar from '../Avatar';
import UserProperty from './UserProperty';

const PersonalInfo = () => { 
	const { currentUser, updateName, updateEmail, updatePassword } = useAuth();
	const [user, setUser] = useState(currentUser);
	const [error, setError] = useState();

	const handleNameChange = async (newName) => {
		try {
			await updateName(newName);
			setUser({...user, displayName: newName});
		}
		catch (error) {
			setError(error.message);
		}
	}

	const handleEmailChange = async (newEmail) => {
		try {
			await updateEmail(newEmail);
			setUser({...user, email: newEmail});
		}
		catch (error) {
			setError(error.message);
		}
	}

	const handlePasswordChange = async (password) => {
		try {
			await updatePassword(password);
		}
		catch (error) {
			setError(error.message);
		}
	}

	return (
		user && (
			<div className="personal-info">
				<div className="properties space-y-8 mb-4">
                    <div className="avatar my-12">
					    <Avatar image={user.photoURL} size='large'/>
                    </div>

					<UserProperty 
						propertyName="Full Name"
						propertyValue={user.displayName}
						submitHandler={handleNameChange}
					/>
					<UserProperty 
						propertyName="Email"
						propertyValue={user.email}
						submitHandler={handleEmailChange}
					/>
					<UserProperty 
						propertyName="Password"
						propertyValue="●●●●●●●●●●●●"
						submitHandler={handlePasswordChange}
					/>
				</div>
				
				{ error && <div className="error-message flex items-center p-4 text-red-800 bg-red-100 border border-red-200 rounded-md">{error}</div> }
			</div>
		)
	);
}

export default PersonalInfo;