import React from 'react';
import { Link } from 'react-router-dom';
import FeaturedAgents from '../FeaturedAgents';
import FeaturedGenres from './FeaturedGenres';
import Lists from './Lists';
import { useAuth } from '../../contexts/AuthContext';
import useDocumentTitle from '../../hooks/useDocumentTitle';

const Dashboard = () => {
	const { currentUser } = useAuth();
	useDocumentTitle('My Dashboard');

	const currentUserFirstName = currentUser.displayName.substr(0, currentUser.displayName.indexOf(' '));

	return (
		<div className="container py-12 space-y-24 md:py-16 px-8 xl:px-32 2xl:px-48">
			<h2>Hi {currentUserFirstName}!</h2>
            
			<Lists />
            
            <div className="feature-agents">
                <div className="flex items-center justify-between mb-4">
                    <h4>Research Agent Profiles</h4>
                    <Link to="/agents"><button className="tertiary">See All</button></Link>
                </div>
                <div className="divider bg-primary w-24 h-1 mb-8"></div>
                <FeaturedAgents />
            </div>

			<FeaturedGenres />
		</div>
	);
}

export default Dashboard;