import React from 'react';
import hk_avatar from '../../assets/hk-avatar.png';

const FoundersNoteSection = () => { 
	return (
		<div className="container relative px-8 my-12 md:my-16 md:px-0 max-w-2xl">
			<div className="
				bg bg-secondary-pink rounded-xl
				absolute top-0 bottom-0 left-0 right-0
				transform -rotate-6
			"></div>
			<div className="
				note-card relative bg-white p-8 md:p-16 shadow-xl rounded-xl space-y-4 
				text-xl leading-normal
				max-w-2xl m-auto
			">
				<p>Let’s face it! Querying sucks. First you have to do research, short-list agents, then tailor your query over and over and over again.</p>

				<p>There’s a lot of rejection or “passes,” and well it can be pretty darn stress-inducing.</p>

				<p>Of course, nothing compares to the elation of waking up to a full manuscript request, a contract, and that blessed day when you ink your signature and get to put ‘repped by @soandso’ in your Twitter bio.</p>

				<p>But, what if the whole experience could be a little more fun? And what’s more fun than getting support from the world’s most loved animal (biased opinion) while you’re on this journey? Meow.</p>

				<p>QueryCat was built for authors by an author and we’re just getting started. I’d love for you to give the app a try.</p>

				<p>Sincerely,</p>
				<img src={hk_avatar} alt="Humayun Khan" className="w-32" />
				<p className="font-medium">Humayun Khan</p>
				<span>Founder of QueryCat & Author</span>
			</div>
		</div>
	);
}

export default FoundersNoteSection;