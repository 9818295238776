import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import agentAPI from '../../api/agentAPI';
import AgentCard from '../AgentCard';
import AgentLoader from '../AgentLoader';

const SimilarAgents = ({agent}) => {
	const [agents, setAgents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const getSimilarAgents = async () => {
			try {
				const limit = 3;
				const agents = await agentAPI.fetchSimilar(agent._id, limit);
				setAgents(agents);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		}

		getSimilarAgents();
	}, [agent._id])

	const renderAgentLoaders = () => {
		var agentLoaders = [];
		for (let index = 0; index < 3; index++) {
			agentLoaders.push(<AgentLoader key={index} />)
		}
		return agentLoaders;
	}

	return (
		<div className="similar-agents flex flex-col items-center">
			<div className="header mb-8">
				<h5>Similar Agents</h5>
				<div className="line h-1 w-20 mt-4 mx-auto bg-primary rounded-full"></div>
			</div>
			<div className="agent-list grid gap-4 grid-cols-1 lg:grid-cols-3">
				{ isLoading ? (
					renderAgentLoaders()
				) : (
					agents.map(agent =>
						<AgentCard agent={agent} key={agent._id} />
					)
				)}
			</div>
		</div>
	);
}

SimilarAgents.propTypes = {
	agent: PropTypes.object.isRequired,
}

export default SimilarAgents;