import React, { useContext, useState, useEffect } from 'react';
import { auth, googleProvider, twitterProvider } from '../firebase';
import { useLists } from './ListsContext';
import userAPI from '../api/userAPI';

const AuthContext = React.createContext()

export function useAuth() {
	return useContext(AuthContext)
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState();
	const [isUserPremium, setIsUserPremium] = useState(false);
	const [isAdmin, setIsAdmin] = useState(false);
	const [loading, setLoading] = useState(true);
	const { fetchLists } = useLists();

	async function signup(email, password, fullName) {
		return auth.createUserWithEmailAndPassword(email, password).then(async userCredential => {
			var user = userCredential.user;
			await user.updateProfile({displayName: fullName});
			await registerDbUser(user);

			return userCredential;
		});
	}

	async function loginWithGoogle() {
		return auth.signInWithPopup(googleProvider).then(async userCredential => {
			var user = userCredential.user;
			await registerDbUser(user);
			
			return userCredential;
		});
	}

	async function loginWithTwitter() {
		return auth.signInWithPopup(twitterProvider).then(async userCredential => {
			var user = userCredential.user;
			await registerDbUser(user);
			
			return userCredential;
		});
	}

	async function login(email, password) {
		return auth.signInWithEmailAndPassword(email, password).then(async userCredential => {
			var user = userCredential.user;
			await registerDbUser(user);
			
			return userCredential;
		});
	}

	async function logout() {
		return auth.signOut();
	}

	function resetPassword(email) {
		return auth.sendPasswordResetEmail(email);
	}

	function updateName(name) {
		return currentUser.updateProfile({displayName: name});
	}

	function updateEmail(email) {
		return currentUser.updateEmail(email);
	}

	function updatePassword(password) {
		return currentUser.updatePassword(password);
	}

	// Updates user tokens when we need to check if they are premiumUser (this relies on an async stripe webhook that is slow to update the user sometimes)
	async function refreshUserToken() {
		if (currentUser) {
			const tokenResult = await currentUser.getIdTokenResult(true);
			setIsUserPremium(tokenResult && tokenResult.claims.premiumPlan ? true : false);
			setIsAdmin(tokenResult && tokenResult.claims.admin ? true : false);
		}
	}

	function setCurrentUserPremium() {
		setIsUserPremium(true);
	}

	 // If user does not exist in db, create them
	async function registerDbUser(user) {
		const dbUser = await userAPI.fetchCurrent();
		if(!dbUser) { await userAPI.register(user) }
	}

	async function setUserPermissions(user) {
		if (user) {
			const tokenResult = await user.getIdTokenResult(true);
			setIsUserPremium(tokenResult && tokenResult.claims.premiumPlan ? true : false);
			setIsAdmin(tokenResult && tokenResult.claims.admin ? true : false);
		}
		else {
			setIsUserPremium(false);
			setIsAdmin(false);
		}
	}
	
	useEffect(() => {
		const unsubscribe = auth.onAuthStateChanged(async user => {
			// User logged in
			if(user) { 
				await fetchLists();
			}

			await setUserPermissions(user);
			setCurrentUser(user);
			setLoading(false);
		})

		return unsubscribe;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const value = {
		currentUser,
		isAdmin,
		isUserPremium,
		signup,
		loginWithGoogle,
		loginWithTwitter,
		login,
		logout,
		refreshUserToken,
		resetPassword,
		setCurrentUserPremium,
		updateName,
		updateEmail,
		updatePassword,
	}

	return (
		<AuthContext.Provider value={value}>
			{!loading && children}
		</AuthContext.Provider>
	)
}