import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import agentAPI from '../api/agentAPI';
import Select from 'react-select';
import { IoSearch } from 'react-icons/io5';

const AgentSearchBar = ({size}) => {
	const reactSelect = useRef(null); // ref used to control blur on submit
	const [query, setQuery] = useState('');
	const [genreOptions, setGenreOptions] = useState([]);
	const [isFocused, setIsFocused] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	let history = useHistory();

	useEffect(() => {
		const getGenres = async () => {
			try {
				const genres = await agentAPI.fetchGenres();
				setGenreOptions(genres);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		}
		getGenres();
	}, [])

	const formatOptionLabel = ({ _id, count }) => (
		<div className="flex justify-between">
			<div>{_id}</div>
			<div className="text-gray-400">{`${count} agents`}</div>
		</div>
	)

	const handleBlur = () => {
		setIsFocused(false);
	}

	const handleButtonClick = () => {
		handleSearchSubmit(query);
	}

	const handleFocus = () => {
		setIsFocused(true);
	}

	const handleInputChange = (inputValue, action) => {
		if (action.action !== "input-blur" && action.action !== "menu-close") {
			setQuery(inputValue);
		}
	}

	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleSearchSubmit(query);
		}
	}

	const handleOptionChange = (selectedOption) => {
		setQuery(selectedOption._id);
		handleSearchSubmit(selectedOption._id);
	}

	const handleSearchSubmit = (searchTerm) => {
		var search = '';
		if(searchTerm) { search =`?search=${searchTerm}` };

		history.push({
			pathname: '/agents',
			search: search
		});
		setQuery('');
		reactSelect.current.blur();
	}

	return (
		<div className="agent-search-bar relative w-full">
			<Select
				className={`agent-search select ${size}`}
				classNamePrefix="select"
				components={{IndicatorSeparator: () => null}}
				formatOptionLabel={formatOptionLabel}
				getOptionValue={(option) => option._id}
				inputValue={query}
				value={query}
				isLoading={isLoading}
				noOptionsMessage={() => 'Hit the search button to look up an agent or agency by that name'}
				onBlur={handleBlur}
				onChange={handleOptionChange}
				onFocus={handleFocus}
				onInputChange={handleInputChange}
				onKeyDown={handleKeyDown}
				options={genreOptions}
				placeholder={'Search for genres, agents, agencies, books, and authors'}
				ref={reactSelect}
			/>
			<button className={size + " primary absolute h-auto min-w-0 inset-y-1 right-1 px-4 flex items-center"}
				onClick={handleButtonClick}
			>
				<IoSearch size="20px" className="mx-1"/>
				<span className={(isFocused ? "max-w-2xs" : "") + " max-w-0 overflow-hidden transition-all duration-500" }>Search</span>
			</button>
		</div>
	);
}

export default AgentSearchBar;