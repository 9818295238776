import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { useAuth } from '../../contexts/AuthContext';
import { useLists } from '../../contexts/ListsContext';
import { useForm } from 'react-hook-form';
import { FcGoogle } from 'react-icons/fc';
import { IoMdHeartEmpty, IoIosList } from 'react-icons/io';
import { IoEyeOffOutline } from 'react-icons/io5';
import { IoLogoTwitter } from 'react-icons/io';
import logo from '../../assets/nav_logo.svg';
import sidebar_image from '../../assets/signup.png';

const SignUp = () => {
	const { register, handleSubmit, errors } = useForm();
	const { signup, loginWithGoogle, loginWithTwitter } = useAuth();
	const { fetchLists } = useLists();
	const [error, setError] = useState('');
	const history = useHistory();
	// const navigateTo = location.state ? location.state.from.pathname : '/';

	const handleGoogleClick = async () => {
		try {
			await loginWithGoogle().then(async () => {
				await fetchLists(); // Need to fetch lists after default list is created for the first time in userApi.register
				ReactPixel.track('CompleteRegistration', { content_name : 'google' });
				history.push('/dashboard');
			});
		} catch (error) {
			setError(error.message);
		}
	}

	const handleTwitterClick = async () => {
		try {
			await loginWithTwitter().then(async () => {
				await fetchLists(); // Need to fetch lists after default list is created for the first time in userApi.register
				ReactPixel.track('CompleteRegistration', { content_name : 'twitter' });
				history.push('/dashboard');
			});
		} catch (error) {
			setError(error.message);
		}
	}

	const onSubmit = async (data) => {
		try {
			await signup(data.email, data.password, data.fullName).then(async () => {
				await fetchLists(); // Need to fetch lists after default list is created for the first time in userApi.register
				ReactPixel.track('CompleteRegistration', { content_name : 'email' });
				history.push('/dashboard');
			})
		} catch (error) {
			setError(error.message);
		}
	}
	
	return (
		<div className="flex flex-row w-full">
			<div className="sidebar hidden lg:flex flex-col bg-secondary-beige w-1/3 py-16 px-16 xl:px-32">
				<Link to="/" className="logo text-xl font-medium">
					<img src={logo} alt="logo" className="h-10 mb-16"/>
				</Link>
				<div className="my-auto">
					<h3 className="mb-8">Meow!<br/>Sign up for QueryCat</h3>
					<div className="body-1 flex flex-col space-y-4 mb-16">
						<span className="flex items-center space-x-2">
							<IoMdHeartEmpty size="32px" className="text-white bg-primary rounded-full p-1.5"/>
							<span>Save <span className="text-primary">unlimited</span> agents</span>
						</span>
						<span className="flex items-center space-x-2">
							<IoIosList size="32px" className="text-white bg-primary rounded-full p-1.5"/>
							<span>Create <span className="text-primary">unlimited</span> lists</span>
						</span>
						<span className="flex items-center space-x-2">
							<IoEyeOffOutline size="32px" className="text-white bg-primary rounded-full p-1.5"/>
							<span><span className="text-primary">Add </span> private (unlisted) agents</span>
						</span>
						{/* <span className="flex items-center space-x-2">
							<BiLockOpenAlt size="32px" className="text-white bg-primary rounded-full p-1.5"/>
							<span>Unlock <span className="text-primary">1500+</span> agent profiles</span>
						</span> */}
					</div>
					<img src={sidebar_image} alt="cat" className="mx-auto"/>
				</div>
			</div>
			<div className="sign-up flex flex-grow">
				<div className="m-auto flex flex-col space-y-8 px-8 p-16 w-full md:p-8 md:w-112">
					<Link to="/" className="logo flex justify-center mb-4 lg:hidden">
						<img src={logo} alt="logo" className="h-14"/>
					</Link>

					<div className="header flex flex-col items-center space-y-4">
						<h6 className="mt-2">Create An Account (Hope You're A Cat Person)</h6>
					</div>
					
					<button onClick={handleGoogleClick} className="secondary large w-full relative">
						<FcGoogle size="32px" className="absolute left-6"/>
						<span className="font-normal">Continue with Google</span>
					</button>
					<button onClick={handleTwitterClick} className="secondary large w-full relative">
						<IoLogoTwitter size="32px" color="#1DA1F2" className="absolute left-6"/>
						<span className="font-normal">Continue with Twitter</span>
					</button>

					<div className="divider flex flex-row justify-center items-center">
						<div className="line flex-grow bg-gray-200 h-0.5 rounded-full"></div>
						<span className="mx-2 text-md font-bold text-gray-400">OR</span>
						<div className="line flex-grow bg-gray-200 h-0.5 rounded-full"></div>
					</div>

					<form className="flex flex-col space-y-6" onSubmit={handleSubmit(onSubmit)}>
						<div className="fields space-y-4">
							<div className="form-input">
								<label>Full Name</label>
								<input 
									type='text' 
									name='fullName' 
									ref={register({ required: 'Full name is required' })} 
								/>
								{ errors.fullName && <p className="error">{errors.fullName.message}</p> }
							</div>
							<div className="form-input">
								<label>Email</label>
								<input 
									type='text' 
									name='email' 
									ref={register({ 
										required: 'Email is required', 
										pattern: {
											value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g,
											message: 'Invalid email format'
										} 
									})} 
								/>
								{ errors.email && <p className="error">{errors.email.message}</p> }
							</div>
							<div className="form-input">
								<label>Password</label>
								<input 
									type='password' 
									name='password' 
									ref={register({ required: 'Password is required' })} 
								/>
								{ errors.password && <p className="error">{errors.password.message}</p> }
							</div>
						</div>

						<p className="terms">
							<span>Creating an account means you’re okay with our </span>
							<a className="text-primary underline" href="/about/terms" target="_blank" rel="noreferrer">Terms of Service</a>
							<span> and </span>
							<a className="text-primary underline" href="/about/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>
						</p>

						<button type="submit" className="primary w-full">Next</button>

						{ error && <div className="error-message flex items-center p-4 text-red-800 bg-red-100 border border-red-200 rounded-md">{error}</div> }
					
						<div className="flex flex-row justify-center">
							<span>Already have an account?</span>
							<button className="link ml-2">
								<Link to="/login">Log in</Link>
							</button>
						</div>
					</form>

				</div>
			</div>
		</div>
	);
}

export default SignUp;