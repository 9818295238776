import React from 'react';
import PropTypes from 'prop-types'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import EmptyState from '../EmptyState';

const TabContent = ({agent}) => {

	return (
		<Tabs>
			<TabList>
				<Tab>Bio</Tab>
				<Tab>Wishlist</Tab>
				<Tab>Guidelines</Tab>
			</TabList>

			<TabPanel>
				{ agent.bio || agent.clients.length || agent.books_sold.length? (
					<div className="fiction mb-8">
						<div className="rich-text mb-8" dangerouslySetInnerHTML={{__html: agent.bio}}></div>
						<div className="
							flex flex-col 
							md:space-x-16 md:flex-row
						">
							{ agent.clients.length > 0 && 
								<div className="clients mb-8">
									<h6 className="mb-2 text-gray-900">Authors Represented</h6>
									<ul className="list-disc list-inside">
										{ agent.clients.map(client => 
											<li key={client}>{client}</li>
										)}
									</ul>
								</div>
							}

							{ agent.books_sold && agent.books_sold.length > 0 && 
								<div className="books-sold mb-8">
									<h6 className="mb-2 text-gray-900">Books Sold</h6>
									<ul className="list-disc list-inside">
										{agent.books_sold.map(genre => 
											<li key={genre}>{genre}</li>
										)}
									</ul>
								</div>
							}
						</div>
					</div>
				) : (  
					<EmptyState
						primaryText="Hmmmm....something appears to be missing"
						secondaryText="Check out the agency site for more details"
					/>
				)}
			</TabPanel>

			<TabPanel>
				{ agent.special_interests || agent.fiction_genres.length || agent.non_fiction_genres.length || agent.not_representing ? (
					<div className="genres">
						<h5 className="mb-4 text-gray-900">Looking for</h5>
						<div className="rich-text mb-8" dangerouslySetInnerHTML={{__html: agent.special_interests}}></div>
						<div className="
							looking-for flex flex-col 
							md:space-x-16 md:flex-row
						">
							{ agent.fiction_genres.length > 0 && 
								<div className="fiction mb-8">
									<h6 className="mb-2 text-gray-900">Fiction</h6>
									<ul className="list-disc list-inside">
										{agent.fiction_genres.map(genre => 
											<li key={genre}>{genre}</li>
										)}
									</ul>
								</div>
							}

							{ agent.non_fiction_genres.length > 0 && 
								<div className="non-fiction mb-8">
									<h6 className="mb-2 text-gray-900">Non-Fiction</h6>
									<ul className="list-disc list-inside">
										{agent.non_fiction_genres.map(genre => 
											<li key={genre}>{genre}</li>
										)}
									</ul>
								</div>
							}
						</div>

						{ agent.not_representing && 
							<div className="not-looking-for">
								<h5 className="mb-4 text-gray-900">Not looking for</h5>
								<div className="rich-text mb-8" dangerouslySetInnerHTML={{__html: agent.not_representing}}></div>
							</div>
						}
					</div>
				) : (  
					<EmptyState
						primaryText="Hmmmm....something appears to be missing"
						secondaryText="Check out the agency site for more details"
					/>
				)}
			</TabPanel>

			<TabPanel>
				{ agent.submission_guidelines? (
					<div className="guidelines">
						<div className="rich-text mb-8" dangerouslySetInnerHTML={{__html: agent.submission_guidelines}}></div>
						<div className="warning-message flex flex-col sm:flex-row space-y-2 items-center p-6 text-gray-600 bg-gray-100 rounded-md">
							<AiOutlineExclamationCircle size="40px" className="flex-shrink-0 mr-4 text-yellow-300"/>
							<div className="message">
								<h6>Please make sure to verify the agent’s info before sending your query</h6>
								<span className="subtitle-1">Cats have nine lives, unfortunately writers who are querying don’t get that many chances</span>
							</div>
						</div>
					</div>
				) : (  
					<EmptyState
						primaryText="Hmmmm....something appears to be missing"
						secondaryText="Check out the agency site for more details"
					/>
				)}
			</TabPanel>
		</Tabs>
	);
}

TabContent.propTypes = {
	agent: PropTypes.object.isRequired,
};

export default TabContent;