import React, { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';

const UserProperty = ({propertyName, propertyValue, submitHandler}) => {
	const { register, handleSubmit } = useForm();
	const [isEditting, setIsEditting] = useState(false);
	const inputRef = useRef();

	const handleEdit = async () => {
		await setIsEditting(true);
		inputRef.current.focus();
		inputRef.current.select()
	}

	const onSubmit = (data) => {
		if (data.value !== propertyValue) {
			submitHandler(data.value);
		}
		setIsEditting(false);
	}

	return (
		<div className="user-property border-b border-gray-200 pb-4">
			{ isEditting ? (
				<form onSubmit={handleSubmit(onSubmit)} className="flex flex-row justify-between">
					<div className="form-input w-5/6">
						<label>{propertyName}</label>
						<input
							type='text' 
							name='value'
							defaultValue={propertyValue}
							ref={ref => {
								register(ref, { required: `${propertyName} is required`})
								inputRef.current = ref
							}}
						/>
					</div>
					<div className="flex items-end ml-4">
						<div className="flex space-x-4">
							<button type="button" className="link" onClick={() => setIsEditting(false)}>Cancel</button>
							<button type="submit" className="primary">Save</button>
						</div>
					</div>
				</form>
			) : (
				<div className="flex flex-row justify-between">
					<div className="flex flex-col">
						<span className="body-2">{propertyName}</span>
						<span className="body-1 font-medium">{propertyValue}</span>
					</div>
					<button className="link" onClick={handleEdit}>Edit</button>
				</div>
			)}
		</div>
	);
}

export default UserProperty;