import React from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { useAuth } from '../contexts/AuthContext';
import AgentSearchBar from './AgentSearchBar';
import NavMenu from './NavMenu';
import NavHamburger from './NavHamburger';
import nav_logo from '../assets/nav_logo.svg';

const Nav = () => { 
	const { currentUser, isAdmin } = useAuth();
	const history = useHistory();
	const isOnHomepage = useRouteMatch('/').isExact;
	// const isOnPricingPage = useRouteMatch('/about/pricing');
	const showSearchBar = true;

	const handleBlogClick = () => {
		ReactPixel.track('ViewContent', { content_name : 'blog' });
	}

	const onClickSignUp = () => {
		ReactPixel.track('InitiateCheckout', { content_category : 'nav' });
		history.push({
			pathname: '/signup',
			state: { from: history.location }
		});
	}

	return (
		<nav className="
			nav fixed w-full flex flex-wrap justify-between top-0 px-6 py-4 z-50
			bg-white shadow
			lg:py-6
		">
			<div className="
				nav-left w-1/2 my-2 flex justify-start items-center
				lg:w-auto lg:order-1 lg:my-auto
			">
				<Link to="/" className="logo text-xl font-medium mr-8">
					<img src={nav_logo} alt="nav logo" className="h-10 my-1" />
				</Link>

				{ isOnHomepage &&
					<div className="links hidden lg:block text-xl font-medium space-x-8">
						<a href="https://blog.querycat.co/" onClick={handleBlogClick} >Blog</a>
					</div>
				}
			</div>

			{ showSearchBar &&
				<div className="
					nav-center order-3 w-full mt-2 flex items-center
					lg:w-160 lg:order-2 lg:my-auto
				">
					<AgentSearchBar />
				</div>
			}

			<div className="
				nav-right w-1/2 my-2 flex justify-end items-center
				lg:w-auto lg:order-3 lg:my-auto
			">
				{ currentUser ? (
					<div className="flex items-center space-x-4">
						{ isAdmin && 
							<Link to="/admin">
								<button className="tertiary truncate">Admin Panel</button>
							</Link>
						}
						<Link to="/dashboard">
							<button className="tertiary truncate">My Dashboard</button>
						</Link>
						<NavMenu />
					</div>
				) : (
					<div className="no-user">
						<div className="desktop hidden lg:flex">
							<Link to="/login" className="flex items-center mx-8">
								<button className="link">Log In</button>
							</Link>
							<button className="primary" onClick={onClickSignUp}>Sign Up</button>
						</div>
						<div className ="mobile lg:hidden">
							<NavHamburger />
						</div>
					</div>
				)}
			</div>
		</nav> 
	);
}

export default Nav;