import React from 'react';
import earnie_detective from '../assets/earnie_detective.png';

const EmptyState = ({primaryText, secondaryText}) => {
	return (
		<div className="empty-state text-center">
			<img src={earnie_detective} alt="empty" className="w-80 mt-16 mx-auto"/>
			{ primaryText && <h5 className="mb-2">{primaryText}</h5> }
			{ secondaryText && <h6 className="text-gray-500">{secondaryText}</h6> }
		</div>
	);
}

export default EmptyState;