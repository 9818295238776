import React, { useState } from 'react';
import PropTypes from 'prop-types';
import reportAPI from '../../api/reportAPI';
import Modal from 'react-modal';
import { IoMdClose } from 'react-icons/io';

const ReportAgent = ({agent}) => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const [isNotAcceptingQueries, setIsNotAcceptingQueries] = useState(false);
	const [isWrongInfo, setIsWrongInfo] = useState(false);
	const [isNotAtAgency, setIsNotAtAgency] = useState(false);
	const [isNotAgent, setIsNotAgent] = useState(false);

	const handleSubmit = () => {
		const reasons = [];
		if (isNotAcceptingQueries) { reasons.push('This agent is not accepting queries') }
		if (isWrongInfo) { reasons.push('The information in the bio, genre, submission is wrong') }
		if (isNotAtAgency) { reasons.push('This agent is no longer at this agency') }
		if (isNotAgent) { reasons.push('This agent no longer works as an agent') }

		const report = {
			agent_id: agent._id,
			agent_name: agent.name,
			reason: reasons,
		};

		reportAPI.createReport(report);
		handleClose();
	}

	const handleClose = () => { 
		setIsNotAcceptingQueries(false);
		setIsWrongInfo(false);
		setIsNotAtAgency(false);
		setIsNotAgent(false);
		
		setIsOpen(false);
	}
	
	return (
		<div>
			<div className="report-card p-8 px-10 space-y-4 border border-grey-200 rounded-lg">
				<h6 className="text-gray-900">Something not right?</h6>
				<p className="subtitle-1 text-gray-900">Did we get something wrong on this agent profile, let us know.</p>
				<button className="link" onClick={() => setIsOpen(true)}>Report Profile</button>
			</div>

			<Modal
				className="modal"
				overlayClassName="modal-overlay"
				isOpen={modalIsOpen}
				contentLabel="Report Profile"
                onRequestClose={handleClose}
			>
				<header className="flex justify-between items-center p-4 border-b">
					<h6>Report this Profile</h6>
					<IoMdClose size="24px" className="cursor-pointer hover:text-gray-700 transition" onClick={handleClose} />
				</header>
				<div className="content p-8 w-full">
					<form className="flex flex-col space-y-4 body-1">
						<div className="checkbox"> 
							<label className="inline-flex items-center cursor-pointer">
								<input 
									type="checkbox" 
									className="
										h-6 w-6 mr-3 text-primary rounded border-gray-300 border-2
										focus:ring focus:ring-primary focus:ring-opacity-50
									"
									value={isNotAcceptingQueries}
									onChange={() => setIsNotAcceptingQueries(!isNotAcceptingQueries)} 
								/>
								<span>This agent is not accepting queries</span>
							</label>
						</div>
						<div className="checkbox"> 
							<label className="inline-flex items-center cursor-pointer">
								<input 
									type="checkbox"
									className="
										h-6 w-6 mr-3 text-primary rounded border-gray-300 border-2
										focus:ring focus:ring-primary focus:ring-opacity-50
									"
									value={isWrongInfo}
									onChange={() => setIsWrongInfo(!isWrongInfo)} 
								/>
								<span>The information in the bio, genre, submission is wrong</span>
							</label>
						</div>
						<div className="checkbox"> 
							<label className="inline-flex items-center cursor-pointer">
								<input 
									type="checkbox"
									className="
										h-6 w-6 mr-3 text-primary rounded border-gray-300 border-2
										focus:ring focus:ring-primary focus:ring-opacity-50
									"
									value={isNotAtAgency}
									onChange={() => setIsNotAtAgency(!isNotAtAgency)} 
								/>
								<span>This agent is no longer at this agency</span>
							</label>
						</div>
						<div className="checkbox"> 
							<label className="inline-flex items-center cursor-pointer">
								<input 
									type="checkbox"
									className="
										h-6 w-6 mr-3 text-primary rounded border-gray-300 border-2
										focus:ring focus:ring-primary focus:ring-opacity-50
									" 
									value={isNotAgent}
									onChange={() => setIsNotAgent(!isNotAgent)} 
								/>
								<span>This agent no longer works as an agent</span>
							</label>
						</div>
					</form>
				</div>
				<footer className="flex justify-between p-4 border-t">
					<button className="link ml-2" onClick={handleClose}>Cancel</button>
					<button className="primary" onClick={handleSubmit}>Report</button>
				</footer>
			</Modal>
		</div>
	);
}

ReportAgent.propTypes = {
	agent: PropTypes.object.isRequired,
}

export default ReportAgent;