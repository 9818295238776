import React from 'react';
import feature_1 from '../../assets/feature-1.png';
import feature_2 from '../../assets/02-shortlist agents.png';
import feature_3 from '../../assets/feature-2.png';

const FeaturesSection = () => { 
	return (
		<div className="
			features-section container flex flex-col items-center p-8 space-y-10
			lg:p-16 lg:space-y-24
			xl:px-32 2xl:px-48
		">
			<div className="header">
				<h4>How It Works</h4>
				<div className="line h-1 w-20 mt-4 mx-auto bg-primary rounded-full"></div>
			</div>

			<div className="
				feature flex flex-col items-center
				lg:flex-row lg:space-x-16
			">
				<div className="description flex flex-col space-y-4 mb-8 lg:mb-0 lg:w-1/3">
					<h5>01</h5>
					<h5>Research In-Depth Agent Profiles</h5>
					<div className="line h-1 w-20 bg-primary rounded-full"></div>
					<p className="text-xl leading-8">
						We’ve done the legwork for you. Go ahead and research hundreds of agents and filter by <span className="font-bold text-secondary-yellow">genre</span>, who’s <span className="font-bold text-secondary-yellow">actively looking</span> for submissions, and who’s on <span className="font-bold text-secondary-yellow">Twitter</span>.
					</p>
				</div>
				<img src={feature_1} alt="Enhanced Agent Profiles" className="lg:w-2/3" />
			</div>

			<div className="
				feature flex flex-col items-center
				lg:flex-row lg:space-x-16
			">
				<img src={feature_2} alt="Track Your Queries" className="order-2 lg:order-1 lg:w-1/2 lg:mr-16" />
				<div className="description flex flex-col space-y-4 mb-8 lg:order-2 lg:mb-0 lg:w-1/3">
					<h5>02</h5>
					<h5>Shortlist Agents</h5>
					<div className="line h-1 w-20 bg-primary rounded-full"></div>
					<p className="text-xl leading-8">
						Find an agent you love? Save them to a list, matter of fact save them to as many lists as you fancy. Create <span className="font-bold text-secondary-yellow">targeted lists</span> and query multiple books at the same time.
					</p>
				</div>
			</div>

			<div className="
				feature flex flex-col items-center
				lg:flex-row lg:space-x-16
			">
				<div className="description flex flex-col space-y-4 mb-8 lg:mb-0 lg:w-1/3">
					<h5>03</h5>
					<h5>Track Your Queries</h5>
					<div className="line h-1 w-20 bg-primary rounded-full"></div>
					<p className="text-xl leading-8">
						Ready to start querying? Ditch the spreadsheets, docs, and scraps of paper. Use our <span className="font-bold text-secondary-yellow">streamlined query tracker</span>. We’ve got you covered.
					</p>
				</div>
				<img src={feature_3} alt="Track Your Queries" className="lg:w-2/3" />
			</div>

		</div>
	);
}

export default FeaturesSection;