import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import Modal from 'react-modal';
import { AuthProvider } from './contexts/AuthContext';
import { ListsProvider } from './contexts/ListsContext';

import App from './components/App';
import ScrollToTop from './components/ScrollToTop';
import './index.scss';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY);

Modal.setAppElement('#root');

ReactDOM.render(
	<React.StrictMode>
		<Elements 
            stripe={stripePromise} 
            options={{
                fonts:[{
                    cssSrc: 'https://use.typekit.net/wpo3kxi.css'
                }]
            }}
        >
			<Router>
				<ScrollToTop />
                <ListsProvider>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </ListsProvider>
			</Router>
		</Elements>
	</React.StrictMode>,
	document.getElementById('root')
);