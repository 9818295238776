import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { IoMdClose } from 'react-icons/io';
import { useLists } from '../../contexts/ListsContext';

const EditListButton = ({ id }) => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const { register, handleSubmit, errors } = useForm();
	const { editList } = useLists();

	const onSubmit = (data) => {
		editList(id, data.listName);
		handleClose();
	}
	
	const handleClose = () => {
		setIsOpen(false);
	}

	return (
		<div className="edit-list">
			<button className="link" onClick={() => setIsOpen(true)}>
				Edit List Name
			</button>

			<Modal
				className="modal"
				overlayClassName="modal-overlay"
				isOpen={modalIsOpen}
				contentLabel="Edit List"
				onRequestClose={handleClose}
			>
				<form onSubmit={handleSubmit(onSubmit)} >
					<header className="flex justify-between items-center p-4 border-b">
						<h6>Edit List Name</h6>
						<IoMdClose size="24px" className="cursor-pointer hover:text-gray-700 transition" onClick={handleClose} />
					</header>
					<div className="content p-8 w-full">
						<div className="flex flex-col space-y-4 body-1 md:w-96">
							<div className="form-input">
								<label>List Name</label>
								<input 
									type='text' 
									name='listName'
									autoComplete='off'
									ref={register({ required: 'List name is required' })} 
								/>
								{ errors.listName && <p className="error">{errors.listName.message}</p> }
							</div>
						</div>
					</div>
					<footer className="flex justify-between p-4 border-t">
						<button type="button" className="link ml-2" onClick={handleClose}>Cancel</button>
						<button type="submit" className="primary">Update</button>
					</footer>
				</form>
			</Modal>
		</div>
	);
}

export default EditListButton;