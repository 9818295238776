import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import { Link } from 'react-router-dom';

const Footer = () => { 
	const handleBlogClick = () => {
		ReactPixel.track('ViewContent', { content_name : 'blog' });
	}

	return (
		<footer className="
			footer bg-secondary-maroon body-1 text-white
		">
			<div  className="
				container flex flex-col space-y-8 p-8
				lg:p-16 lg:flex-row lg:space-x-20 lg:space-y-0
				xl:px-32 2xl:px-48
			">
				<div className="section flex flex-col space-y-3">
					<h6 className="text-primary">About</h6>
					<a href="https://blog.querycat.co" target="_blank" rel="noreferrer" className="hover:underline" onClick={handleBlogClick}>Blog</a>
					<Link to="/about/privacy" className="hover:underline">Privacy Policy</Link>
					<Link to="/about/terms" className="hover:underline">Terms & Conditions</Link>
				</div>
				<div className="section flex flex-col space-y-3">
					<h6 className="text-primary">Get in Touch</h6>
					<a href="https://twitter.com/querycatapp" target="_blank" rel="noreferrer" className="hover:underline">Twitter</a>
					<a href="https://www.facebook.com/QueryCatApp" target="_blank" rel="noreferrer" className="hover:underline">Facebook</a>
				</div>
			</div>
		</footer>
	);
}

export default Footer;