import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import FAQSection from './FAQSection';
import FeaturedAgents from '../FeaturedAgents';
import FeaturesSection from './FeaturesSection';
import FoundersNoteSection from './FoundersNoteSection';
import HeroSection from './HeroSection';
import SecondaryCTASection from './SecondaryCTASection';
import mackenzie_testimonial from '../../assets/mackenzie_testimonial.png';

const Home = () => {
	const history = useHistory();
	const { currentUser } = useAuth();

	useEffect(() => {
		if(currentUser) { 
			history.push('/dashboard');
		}
	}, [currentUser, history])

	return (
		!currentUser && (
			<div className="home w-full -mt-14 lg:mt-0">
				<HeroSection />

				<FeaturesSection />

				<div className="featured-agents container p-8 space-y-8 lg:p-16 xl:px-24 2xl:px-48">
                    <h4 className="text-center">Featured Agent Profiles</h4>
                    <div className="line h-1 w-40 mx-auto bg-primary rounded-full"></div>
					<FeaturedAgents />
				</div>

				<img src={mackenzie_testimonial} alt="Mackenzie Belcastro Testimonial" className="p-8 m-auto lg:my-16" />

				<FAQSection />

				<SecondaryCTASection />

				<FoundersNoteSection />
			</div>
		)
	)
}

export default Home;