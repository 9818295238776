import axios from 'axios';
import { auth } from '../firebase';

const API_ROOT = process.env.REACT_APP_API_ROOT;

const tokenPlugin = async () => {
    let token = null;
    if(auth.currentUser) {
        token = await auth.currentUser.getIdToken();
    }

	return { headers: { authorization: `Bearer ${token}` }};
}

const del = async url => axios.delete(`${API_ROOT}${url}`, await tokenPlugin()).then(res => res.data);
const get = async url => axios.get(`${API_ROOT}${url}`, await tokenPlugin()).then(res => res.data);
const put = async (url, body) => axios.put(`${API_ROOT}${url}`, body, await tokenPlugin()).then(res => res.data);
const post = async (url, body) => axios.post(`${API_ROOT}${url}`, body, await tokenPlugin()).then(res => res.data);

export { del, get, put, post };