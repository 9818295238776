import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal from 'react-modal';
import { toast } from 'react-hot-toast';
import { IoMdClose } from 'react-icons/io';
import { useLists } from '../../contexts/ListsContext';
import ActionToast from './ActionToast';

// const freePlanListLimit = 1;

const NewListButton = () => {
	const [modalIsOpen, setIsOpen] = useState(false);
	const { register, handleSubmit, errors } = useForm();
	const { lists, createList } = useLists();
	const canCreateList = true; //isUserPremium || (lists.length < freePlanListLimit);

	const onSubmit = (data) => {
		createList(data.listName);
		handleClose();
	}
	
	const handleOpen = () => {
		if (canCreateList) {
			setIsOpen(true)
		}
		else {
			toast.error((t) => (
				<ActionToast 
					type='error' 
					toastId={t.id} 
					response={{
						title: 'Could Not Create List',
						message: `You’ve created ${lists.length} of 1 lists availble in the free plan.`
					}} 
				/>
			))
		}
	}
	
	const handleClose = () => {
		setIsOpen(false);
	}

	return (
		<div className="new-list">
			<button className="tertiary" onClick={handleOpen}>+ New List</button>

			<Modal
				className="modal"
				overlayClassName="modal-overlay"
				isOpen={modalIsOpen}
				contentLabel="New List"
				onRequestClose={handleClose}
			>
				<form onSubmit={handleSubmit(onSubmit)} >
					<header className="flex justify-between items-center p-4 border-b">
						<h6>New List</h6>
						<IoMdClose size="24px" className="cursor-pointer hover:text-gray-700 transition" onClick={handleClose} />
					</header>
					<div className="content p-8 w-full">
						<div className="flex flex-col space-y-4 body-1 md:w-96">
							<div className="form-input">
								<label>List Name</label>
								<input
									type='text'
									name='listName'
									autoComplete='off'
									ref={register({ required: 'List name is required' })} 
								/>
								{ errors.listName && <p className="error">{errors.listName.message}</p> }
							</div>
						</div>
					</div>
					<footer className="flex justify-between p-4 border-t">
						<button type="button" className="link ml-2" onClick={handleClose}>Cancel</button>
						<button type="submit" className="primary">Create</button>
					</footer>
				</form>
			</Modal>
		</div>
	);
}

export default NewListButton;