import React, { useState } from 'react';
import Modal from 'react-modal';
import { IoMdClose } from 'react-icons/io';
import { BiTrash } from 'react-icons/bi';

const DeleteButton = ({id, deleteHandler, confirmationMessage, icon = false, children}) => {
	const [modalIsOpen, setIsOpen] = useState(false);

	const onSubmit = (id) => {
		deleteHandler(id);
		handleClose();
	}
	
	const handleClose = () => {
		setIsOpen(false);
	}

	return (
		<div className="delete">
				{ icon ? (
					<button className="outline min-w-0 px-2" onClick={() => setIsOpen(true)}>
						<BiTrash size="20px"/> 
					</button>
				) : (
					<button className="link" onClick={() => setIsOpen(true)}>
						{children}
					</button>
				)}

			<Modal
				className="modal"
				overlayClassName="modal-overlay"
				isOpen={modalIsOpen}
				contentLabel="Delete"
				onRequestClose={handleClose}
			>
				<header className="flex justify-between items-center p-4 border-b">
					<h6>Delete?</h6>
					<IoMdClose size="24px" className="cursor-pointer hover:text-gray-700 transition" onClick={handleClose} />
				</header>
				<div className="content p-8 w-full md:w-112">
					<p className="body-1">{confirmationMessage}</p>
				</div>
				<footer className="flex justify-between p-4 border-t">
					<button type="button" className="link ml-2" onClick={handleClose}>Cancel</button>
					<button className="primary" onClick={() => onSubmit(id)}>Delete</button>
				</footer>
			</Modal>
		</div>
	);
}

export default DeleteButton;