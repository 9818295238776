import React, { useState, useEffect } from 'react';
import agentAPI from '../api/agentAPI';
import AgentCard from './AgentCard';
import AgentLoader from './AgentLoader';

const FeaturedAgents = () => {
	const [agents, setAgents] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchFeaturedAgents = async () => {
			try {
				const data = await agentAPI.fetchFeatured();
				setAgents(data);
				setIsLoading(false);
			} catch (error) {
				console.log(error);
			}
		}

		fetchFeaturedAgents();
	}, [])

	const renderAgentLoaders = () => {
		var agentLoaders = [];
		for (let index = 0; index < 6; index++) {
			agentLoaders.push(<AgentLoader key={index} />)
		}
		return agentLoaders;
	}

	return (
        <div className="agent-list grid gap-4 grid-cols-1 md:grid-cols-2 xl:grid-cols-3">
            { isLoading ? (
                renderAgentLoaders()
            ) : (
                agents.map(agent => 
                    <AgentCard agent={agent} key={agent._id} />
                )
            )}
        </div>
	);
}

export default FeaturedAgents;