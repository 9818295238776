import React from 'react';
import { useHistory } from 'react-router-dom';
import Avatar from './Avatar';
import SaveAgentButton from './SaveAgentButton';

const sortGenresByKeyword = (genres, keyword) => {
	return genres.sort((a, b) => {
		// Sort results by matching name with keyword position in name
		if (b.toLowerCase().indexOf(keyword.toLowerCase()) > a.toLowerCase().indexOf(keyword.toLowerCase())) {
			return 1;
		} else {
			return -1;
		}
	});
}

const AgentCard = ({ agent, genreKeyword }) => {
	const history = useHistory();
	const allGenres = agent.fiction_genres.concat(agent.non_fiction_genres);
	const sortedGenres = genreKeyword ? sortGenresByKeyword(allGenres, genreKeyword) : allGenres;
	const genreListLimit = 3;

	const handleClick = () => {
		history.push({
			pathname: `/agent/${encodeURIComponent(agent.name)}`,
			state: { agent: agent }
		});
	}

	return (
		<div className="agent-card relative card cursor-pointer">
			<div className="flex flex-row p-6 h-48 rounded-xl overflow-hidden " onClick={handleClick}>
				<div className="avatar mr-4">
					<Avatar image={agent.twitter_image} size='small' />
				</div>
				<div className="agent-details truncate flex-grow">
					<div className="agency-name overline tracking-wider truncate mb-1">
						{agent.agency}
					</div>
					<div className="flex items-center mb-5">
						<h5 className="agent-name truncate mr-3">
							{agent.name}
						</h5>
						{agent.is_accepting_submissions ? (
							<div className="pill success caption">● Open</div>
						) : (
							<div className="pill error caption">● Closed</div>
						)}
					</div>
					<div className="genre-tags overflow-hidden flex flex-wrap">
						{sortedGenres.length ? (
							<div className="flex flex-wrap">
								{
									sortedGenres.slice(0, genreListLimit).map(genre => {
										return (
											<div className="pill caption mr-2 mb-2" key={genre}>
												{genre}
											</div>
										)
									})
								}
								{sortedGenres.length > genreListLimit &&
									<div className="pill caption mr-2 mb-2">
										{`+${sortedGenres.length - genreListLimit} more`}
									</div>
								}
							</div>
						) : (
							<div className="pill caption mr-2 mb-2">No genres listed</div>
						)}
					</div>
				</div>
			</div>
			<div className="save-button absolute bottom-4 right-4">
				<SaveAgentButton agent={agent} />
			</div>
		</div>
	);
}

export default AgentCard;