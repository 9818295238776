import React from 'react';
import { AccordionItem, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import ReactTooltip from 'react-tooltip';
import pluralize from 'pluralize';
import DeleteButton from '../DeleteButton';
import EditListButton from './EditListButton';
import AddPrivateQueryButton from '../Queries/AddPrivateQueryButton';
import QueriesTable from '../Queries/QueriesTable';
import { useLists } from '../../contexts/ListsContext';

const List = ({ listProp }) => {
	const { deleteList, addPrivateQuery, deleteQuery } = useLists();

	return (   
        <AccordionItem uuid={listProp._id}>
            <AccordionItemButton>
                <div className="title flex items-center space-x-4 truncate">
                    <h5 className="truncate">{listProp.name}</h5> 
                    <h6 className="text-gray-400 truncate">({pluralize('Agent', listProp.queries.length, true)} Saved)</h6> 
                </div>
            </AccordionItemButton>
            <AccordionItemPanel>
                <QueriesTable listProp={listProp} deleteHandler={deleteQuery}/>

                <div className="actions flex justify-between items-center mt-6">
                    <div className="left flex space-x-4">
                        <EditListButton id={listProp._id} />
                        <DeleteButton 
                            id={listProp._id}
                            deleteHandler={deleteList}
                            confirmationMessage="Are you sure? This will permanently delete this list and all saved agents associated with it."
                        >
                            Delete List
                        </DeleteButton>
                    </div>
                    <div className="right flex space-x-4">
                        <AddPrivateQueryButton 
                            addQueryHandler={addPrivateQuery}
                            listId={listProp._id}
                        />
                        
                        <div
                            data-tip
                            data-for="privateAgent"
                            className="flex justify-center items-center h-6 w-6 rounded-full bg-gray-200 text-sm cursor-default"
                        >
                            ?
                        </div>
                        <ReactTooltip id="privateAgent" place="bottom" type="light" effect="solid" className="shadow-md">
                            This agent does not exist in QueryCat <br/> and is for your use only. No one else <br/> has access to this agent.
                        </ReactTooltip>
                    </div>
                </div>
            </AccordionItemPanel>
        </AccordionItem>
	);
}

export default List;