import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import Select from 'react-select';
import Avatar from './Avatar';

const menuOptions = [
	{ value: 'settings', label: 'Settings' },
	{ value: 'logout', label: 'Log out' },
]
const customStyles = {
	menu: (provided) => ({
		...provided,
		width: '200px',
		right: 0,
	})
}

const NavMenu = () => { 
	const { currentUser, logout } = useAuth();
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const history = useHistory();

	const ControlComponent = () => (
		<div onClick={() => setIsMenuOpen(true)} className="cursor-pointer">
			<Avatar image={currentUser.photoURL} onClick={() => setIsMenuOpen(true)} className="cursor-pointer" />
		</div>
	)

	const handleLogout = async () => {
		try {
			await logout();
			history.push('/login');
		} catch (error) {
			console.log(error);
		}
	}

	const handleMenuChange = (selectedOption) => {
		switch (selectedOption.value) {
			case 'logout':
				handleLogout();
				break;
			case 'settings':
				history.push('/settings');
				break;
			default:
				break;
		}
		setIsMenuOpen(false);
	}

	const handleClose = () => {
		setIsMenuOpen(false);
	}

	return (
		<div className="nav-menu">
			<Select
				components={{ Control: ControlComponent }}
				className="select menu"
				classNamePrefix="select"
				isSearchable={false}
				menuIsOpen={isMenuOpen}
				onChange={handleMenuChange}
				options={menuOptions}
				styles={customStyles}
			/>
			{ isMenuOpen &&
				<div onClick={handleClose} className="fixed inset-0 h-full w-full"></div>
			}
		</div>

	);
}

export default NavMenu;