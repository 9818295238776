import React from 'react';
import { Accordion, AccordionItem, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';

const FAQSection = () => {
    return (
        <div className="container py-12 md:py-16 px-8 xl:px-32 2xl:px-48">
            <div className="faqs">
                <h4 className="text-center">Frequently Asked Questions</h4>
                <div className="line h-1 w-40 mx-auto mt-8 mb-4 bg-primary rounded-full"></div>

                <Accordion allowZeroExpanded >
                    <AccordionItem>
                        <AccordionItemButton>
                            What is querying?
                        </AccordionItemButton>
                        <AccordionItemPanel>
                            Querying is a process in traditional publishing whereby an aspiring author with a finished manuscript (if its fiction) or book proposal (if its non-fiction) pitches their work and themselves to a literary agent in order to be signed to their agency for representation.<br /><br />

                            Once you sign a contract with an agent, they will then work with you to get your materials in order and ready to be pitched to editors at a publishing house.
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemButton>
                            Do I need an agent?
                        </AccordionItemButton>
                        <AccordionItemPanel>
                            Not necessarily, the internet and ebooks have it possible for you as a writer to pursue many different paths when it comes to getting your work out there. However, if you're interested in having your work published by a medium to large traditional publisher (ex. Penguin Random House, HarperCollins…etc.) chances are you're going to need an agent to represent you. The reason being, a lot of publishers don't look at manuscripts submitted by authors directly and rely on agents as gatekeepers in the process.
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemButton>
                            I have an incomplete novel, should I be querying?
                        </AccordionItemButton>
                        <AccordionItemPanel>
                            You could be, but we don't recommend it. Think of querying as a "close-up" for your work and so it really ought to be finished and revised to the best of your abilities so as to make the best first impression. However, if you want to get ahead of the game and start your research and shortlisting agents, by all means.
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemButton>
                            Is there a difference between querying fiction and non-fiction works?
                        </AccordionItemButton>
                        <AccordionItemPanel>
                            Yes, there is, but rather than get into it here, we recommend doing a quick Google search to find out what they are.
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemButton>
                            Why should I sign up for QueryCat?
                        </AccordionItemButton>
                        <AccordionItemPanel>
                            Querying is an exhausting process that requires longs hours spent researching agents, shortlisting them and then preparing your querying material. Though we can't magically make it disappear and make it so that any writer who wanted an agent could get one, we think we've made it a little more stress-free.<br /><br />

                            We've created a robust database with all the necessary pieces of information you need to decide if an agent is worth the effort for you and then built tools for your to organize and short list them into lists and track the status of queries all in one tool.<br /><br />

                            Plus, we're just getting started so you can expect lots of handy tools and features that are currently in development to be released in the near future.
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemButton>
                            Do you charge for QueryCat?
                        </AccordionItemButton>
                        <AccordionItemPanel>
                            No, QueryCat is free for everyone to browse and use to track their queries.
                        </AccordionItemPanel>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionItemButton>
                            Can I message agents or query directly on QueryCat?
                        </AccordionItemButton>
                        <AccordionItemPanel>
                            Unfortunately, not yet.<br /><br />

                            But in the interim you'll find all the details around the submission guidelines for each agent on their profile along with their email, links to querying forms and more.
                        </AccordionItemPanel>
                    </AccordionItem>
                </Accordion>
            </div>
        </div>
    );
}

export default FAQSection;