import React, { useState, useRef, useEffect } from 'react';
import ReactGA from 'react-ga';
import Select, { components } from 'react-select';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { IoMdClose } from 'react-icons/io';
import { IoEyeOffOutline } from 'react-icons/io5';
import { parseISO } from 'date-fns';
import DatePicker from "react-datepicker";
import queryAPI from '../../api/queryAPI';
import DeleteButton from '../DeleteButton';

const statusOptions = [
	{ value: 'Waiting', label: '😼 Waiting' },
	{ value: 'Partial Request', label: '😺 Partial Request' },
	{ value: 'Full Request', label: '😸 Full Request' },
	{ value: 'Closed to Queries', label: '😾 Closed to Queries' },
	{ value: 'No Response', label: '😿 No Response' },
	{ value: 'Offer of Representation', label: '😻 Offer of Representation' },
	{ value: 'Other', label: '🙀 Other' }
]
const customStyles = {
	menu: (provided) => ({
		...provided,
		width: '260px',
		right: 0,
	})
}
const ControlComponent = props => (
	<div className="form-input">
		<label className="block lg:hidden">Status</label>
		<components.Control {...props} />
	</div>
);

const Query = ({query, deleteHandler}) => {
	const getStatusOption = (value) => {
		return statusOptions.find((option) => option.value === value)
	}

	const notesRef = useRef(null);
	const [status, setStatus] = useState(getStatusOption(query.status));
	const [queryDate, setQueryDate] = useState(query.date_queried ? parseISO(query.date_queried) : null);
	const [notes, setNotes] = useState(query.notes);

	useEffect(() => {
		const textarea = notesRef.current;
		textarea.style.height = "44px";
	}, [])

	const handleStatusChange = async (selectedOption) => {
		let newQuery = {};
		newQuery.status = selectedOption.value;

		try {
			await queryAPI.updateQuery(query._id, newQuery);
			ReactGA.event({ category: 'Query', action: 'Updated Query Status' });
			setStatus(selectedOption);
		} catch (error) {
			console.log(error);
		}
	}

	const handleDateChange = async (date) => {
		let newQuery = {};
		newQuery.date_queried = date;

		try {
			await queryAPI.updateQuery(query._id, newQuery);
			ReactGA.event({ category: 'Query', action: 'Updated Query Date' });
			setQueryDate(date);
		} catch (error) {
			console.log(error);
		}
	}

	const handleNotesSubmit = async () => {
		let newQuery = {};
		newQuery.notes = notes;

		try {
			await queryAPI.updateQuery(query._id, newQuery);
			ReactGA.event({ category: 'Query', action: 'Updated Query Notes' });
		} catch (error) {
			console.log(error);
		}
	}

	const handleNotesChange = (event) => {
		setNotes(event.target.value);
	}

	const textAreaAdjust = () => {
		const textarea = notesRef.current;
		textarea.style.height = "1px";
		textarea.style.height = (44+textarea.scrollHeight)+"px";
	}

	return (
		<div className="
			query flex flex-wrap p-6
			border border-gray-200 rounded-xl bg-gray-100 bg-opacity-50
			lg:p-0 lg:pt-4 lg:bg-white lg:border-0 lg:border-t lg:rounded-none
		">
			<div className="
				agent-name order-1 w-3/5 mb-8
				lg:p-4 lg:w-1/5 lg:mb-0
			">  
				{ query.is_private_query ? (
					query.private_agent_url ? (
						<a 
							href={query.private_agent_url} 
							target='_blank' rel="noreferrer" 
							className="body-1 font-medium flex items-center mt-2 group underline"
						>   
							<IoEyeOffOutline size="24px" className="mr-2"/>
							{query.private_agent_name}
							<HiOutlineExternalLink size="24px" className="ml-1 opacity-0 group-hover:opacity-100"/>
						</a>
					) : (
						<span className="body-1 font-medium flex items-center mt-2">   
							<IoEyeOffOutline size="24px" className="mr-2"/>
							{query.private_agent_name}
						</span>
					)
				) : (
					<a 
						href={`/agent/${encodeURIComponent(query.agent && query.agent.name)}`} 
						target='_blank' rel="noreferrer" 
						className="body-1 font-medium flex items-center mt-2 group underline"
					>
						{query.agent && query.agent.name}
						<HiOutlineExternalLink size="24px" className="ml-1 opacity-0 group-hover:opacity-100"/>
					</a>
				)}
			</div>
			<div className="
				query-date order-2 w-1/2 pr-2 mb-4
				lg:px-2 lg:p-4 lg:w-1/5 lg:mb-0
			">
				<div className="form-input w-full">
					<label className="block lg:hidden">Date queried</label>
					<DatePicker 
						onChange={handleDateChange}
						selected={queryDate}
						placeholderText="Click to select a date"
					/>
				</div>
			</div>
			<div className="
				query-status order-3 w-1/2 pl-2
				lg:px-2 lg:p-4 lg:w-1/5
			">
				<Select
					components={{ Control: ControlComponent, IndicatorSeparator: () => null }}
					className="select"
					classNamePrefix="select"
					getOptionValue={(option) => option.value}
					isSearchable={false}
					maxMenuHeight={400}
					onChange={handleStatusChange}
					options={statusOptions}
					placeholder="Select Status"
					styles={customStyles}
					value={status}
				/>
			</div>
			<div className="
				notes order-4 w-full flex 
				lg:px-2 lg:p-4 lg:w-auto lg:flex-grow
			">
				<div className="form-input w-full">
					<label className="block lg:hidden">Notes</label>
					<textarea
						placeholder="Add Note"
						onChange={handleNotesChange}
						onBlur={handleNotesSubmit}
						onFocus={textAreaAdjust}
						onKeyUp={textAreaAdjust}
						ref={notesRef}
						value={notes}
					/>
				</div>
			</div>
			<div className="
				delete-button order-1 w-2/5 flex justify-end 
				lg:px-2 lg:p-4 lg:order-5 lg:w-auto
			">
				<DeleteButton 
					id={query._id} 
					icon={true}
					deleteHandler={deleteHandler}
					confirmationMessage={`Are you sure you want to delete this agent? This will permanently delete the agent from this list.`}
					content={<IoMdClose size="24px"/>}
				/>
			</div>
		</div>
	);
}

export default Query;