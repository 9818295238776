import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import Hamburger from 'hamburger-react'
import Select from 'react-select';

const menuOptions = [
	{ value: 'pricing', label: 'Pricing' },
	{ value: 'blog', label: 'Blog' },
	{ value: 'signup', label: 'Sign Up' },
	{ value: 'login', label: 'Log In' },
]
const customStyles = {
	menu: (provided) => ({
		...provided,
		width: '200px',
		right: 0,
	})
}

const NavMenu = () => { 
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const history = useHistory();

	const handleMenuChange = (selectedOption) => {
		switch (selectedOption.value) {
			case 'pricing':
				history.push('/about/pricing');
				break;
			case 'blog':
				ReactPixel.track('ViewContent', { content_name : 'blog' });
				window.location.href = 'https://blog.querycat.co/';
				break;
			case 'login':
				history.push('/login');
				break;
			case 'signup':
				history.push('/signup');
				ReactPixel.track('InitiateCheckout', { content_category : 'nav' });
				break;
			default:
				break;
		}
		setIsMenuOpen(false);
	}

	return (
		<div className="nav-hamburger">
			<Hamburger toggled={isMenuOpen} toggle={setIsMenuOpen} />
			<Select
				components={{ Control: () => null }}
				className="select menu"
				classNamePrefix="select"
				isSearchable={false}
				menuIsOpen={isMenuOpen}
				onChange={handleMenuChange}
				options={menuOptions}
				styles={customStyles}
			/>
		</div>

	);
}

export default NavMenu;